import React, { forwardRef, useEffect, useState } from "react";

import { Avatar, Button, Group, UnstyledButton, Text, UnstyledButtonProps, Menu, Divider, Loader, ActionIcon, Indicator } from "@mantine/core";
import { Language, useLanguage } from "../language/Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, thin, duotone } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { GlobalSecurity } from "./GlobalSecurity";
import { Organisation } from "../api/UserProfile";

export const UserMenu = () => {
  const lang = useLanguage();
  const { isLoggedIn, login, logout, userInfo, isLoading, 
      organisations, currentOrganisationId, switchOrganisation } = GlobalSecurity();

  const renderOrganisations = (
      lang: Language, 
      organisations: Organisation[] | undefined, 
      currentOrgId: string | undefined
    ) => {
    if (organisations!== undefined)
    return <>
      {organisations?.map((org, index) => { 
            return <Menu.Item key={index} onClick={() => switchOrganisation(org.id, org.defaultRoute)}
              leftSection={currentOrgId === org?.id ?
                <FontAwesomeIcon icon={duotone('briefcase-blank')} size="2x" color="#0476d9"/>
                : <FontAwesomeIcon icon={duotone('briefcase-arrow-right')} size="2x" color="#0476d9"/>}
            >
              {currentOrgId === org?.id ?
              <Text className="font-bold">{org?.name}</Text> : <Text >{org?.name}</Text>}
              </Menu.Item>
      })}
    </>;  
  }

  return (
    <>
    {isLoading ? <> 
        <Loader />
    </> : <>
    {isLoggedIn ? 
    <>
    <Menu 
        position='bottom'
        width={300}
      >
      <Menu.Target>
       <ActionIcon size="xl" radius="xl" loading={isLoading}>
        <img className="h-10 w-10 rounded-full" src={userInfo?.picture} alt=''/>  
       </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown className="rounded-lg">
        <div className="p-1">
          <Text className="px-3 text-gray-900"
              size="xl" >{userInfo?.name}
          </Text>
          <Menu.Label>{userInfo?.email}</Menu.Label>
          <Divider className="mt-2"/>
          <div className="py-2">
          {renderOrganisations(lang, organisations, currentOrganisationId)}
          </div>
          </div>
          <div className="p-6 m-[-5px]
            bg-gray-800 border-t border-gray-200 rounded-b-lg
            flex justify-center">
            <Button 
            // color="#ff6900" 
            color="red" radius="xl"
            onClick={() => logout()}> {lang.Text('Logout')}
            </Button>
          </div>
        </Menu.Dropdown>
      </Menu>
        </>
      : <>
        <Indicator disabled color="red" size={15}>
          <Button variant="white" 
              radius="xl" onClick={() => login()}
              leftSection={<FontAwesomeIcon icon={duotone('key')} size="1x"/>}
            >
            {lang.Text('login.btn')}
          </Button>
        </Indicator>
      </>}
      </>}
    </>  
  )
};



interface UserButtonProps extends UnstyledButtonProps {
    image: string;
    name: string;
    email: string;
    icon?: React.ReactNode;
  }
  
  
export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
    ({ image, name, email, icon, ...others }: UserButtonProps, ref) => (
      <UnstyledButton
        ref={ref}
        style={(theme) => ({
          display: 'block',
          width: '100%',
          padding: theme.spacing.md,
          color:  theme.colors.dark[0],
          '&:hover': {
            backgroundColor:
              theme.colors.gray[0],
          },
        })}
        {...others}
      >
        <Group>
          <Avatar src={image} radius="xl" />
  
          <div style={{ flex: 1 }}>
            <Text size="sm" >
              {name}
            </Text>
  
            <Text color="dimmed" size="xs">
              {email}
            </Text>
          </div>
  
          {/* {icon || <ChevronRight size={16} />} */}
        </Group>
      </UnstyledButton>
    )
  );
  
