import React, { useState } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

// import "./App.css";

import { GlobalSecurity } from "../../../common/security/GlobalSecurity";
import { Http, fetcher } from "../../../common/Fetcher";
import { GlobalAppsettings } from "../../../GlobalSettings";

export interface AccountSession {
    success: boolean;
    result : {
        clientSecret: string;
    }
    errorMessage: string;
}

export const StripeConnectContext = (props: {children: any}) => {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {getAccessToken} = GlobalSecurity();
  
  
    const [stripeConnectInstance] = useState(() => {
      const fetchClientSecret = async () => {
        //fetch client secret from api
        console.log("fetching client secret");
        const accessToken = await getAccessToken();
        const data = await fetcher<AccountSession>('/api/payments/getaccountSession', Http.GET, accessToken);    
        if (data.success){
            if (data.result.clientSecret === undefined){
            setHasError(true);
            setErrorMessage("Client secret is undefined");
            return '';
            }
            else
            {
                return data.result.clientSecret;
            }
        }
        else
        {
            setHasError(true);
            setErrorMessage(data.errorMessage);
            return '';
        }    
    }
    
    return loadConnectAndInitialize({
        publishableKey: GlobalAppsettings.StripeKey,
        fetchClientSecret: fetchClientSecret,
        appearance: {
          overlays: 'drawer',
          variables: {
            colorPrimary: '#0476d9',
            spacingUnit: "11px",
            bodyMdFontSize: "24px",
            bodySmFontSize: "16px",
            fontFamily: 'sans-serif',
          },
        },
      })
    });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        {props.children}        
    </ConnectComponentsProvider>
  )
}