import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Button, Text, Group, Select, Title } from '@mantine/core';
import * as React from 'react'
import { Device, Room, ScheduleSettings, Schedule_Item_Session, Schedule_Item_Session_RoomConfiguration } from '../api/Testcenter';
import { useLanguage } from '../../../common/language/Language';
import { useContent } from '../../../common/content/useContent';
import { useForm } from '@mantine/form';
import { Http, fetcher } from '../../../common/Fetcher';
import { ResponseData } from '../../../common/api/common';
import { GlobalAppsettings } from '../../../GlobalSettings';
import { useState } from 'react';
import { AddSessionToScheduleRequest } from '../api/TestcenterRequests';
import { GlobalSecurity } from '../../../common/security/GlobalSecurity';

export interface AddSessionCompProps {
    scheduleSettings: ScheduleSettings;
    addSessionDayOfWeek: string;
    sessionAdded(addedSession: Schedule_Item_Session) : void;
}


interface RoomDeviceCountSelection {
    roomId: string;
    deviceCount: number;
}

interface RoomConfig {
    roomId: string;
    label: string;
    devices: DeviceConfig[];
}

interface DeviceConfig {
    label: string;
    value: string;
}


export const AddSessionComp = (props: AddSessionCompProps) => {

    const lang = useLanguage();
    
    const { accessToken } = GlobalSecurity();

    const [ endTimeSelected, setEndTimeSelected] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [roomDeviceSelections, setRoomDeviceSelections] = useState<RoomDeviceCountSelection[]>([]);
    const [datesOk, setDatesOk] = useState<boolean>(true);
    const [addSessionErrorMessage, setAddSessionErrorMessage] = useState<string>('');

    const createSession = useContent({contentID: '4BYlaqwm0tkXytbdRwmt2S', locale: lang.locale(), preview: false});

    const hours = ["00", "01", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    const minutes = ["00", "05", "10", "15", "20","25", "30", "35", "40", "45", "50", "55" ];

    const addSessionForm = useForm({
      initialValues:  {
        hours: hours,
        minutes: minutes,
        startHours: '08',
        startMinutes: '00',
        endHours: '09',
        endMinutes: '00',
      }
    });
    
    const onSelectChange = (target: any) => {
        console.log(target);

        let splitTarget: string[] = target.toString().split('|');

        let id: string = splitTarget[0];

        let tempRoomSelections = roomDeviceSelections;
        
        let roomSelection: RoomDeviceCountSelection = {
          roomId: '',
          deviceCount: -1
        };

        tempRoomSelections.forEach(tr =>{
            if(tr.roomId === id) {
                roomSelection = tr;
            }
        });

        if(roomSelection.deviceCount != -1){
            let indexOfCollection: number = tempRoomSelections.indexOf(roomSelection);

            if(indexOfCollection != -1){
                tempRoomSelections[indexOfCollection] = roomSelection;
            }
        }
        else {
            roomSelection.deviceCount = +splitTarget[1];
            roomSelection.roomId = splitTarget[0];
            tempRoomSelections.push(roomSelection);
        }

        setRoomDeviceSelections(tempRoomSelections);
    } 

    const CreateRoomSelect = (room: Room) => {

        let roomConfig: RoomConfig = {
            devices:[],
            label: room.name,
            roomId: room.id
        }

        let deviceConfig: DeviceConfig ={
            value: room.id + "|" + (0),
            label: (0).toString()
        }

        roomConfig.devices.push(deviceConfig);

        for(let i = 0; i < room.devices.length; i++){
            let d = room.devices[i];

            let deviceConfig: DeviceConfig ={
                value: room.id + "|" + (i + 1),
                label: (i + 1).toString()
            }

            roomConfig.devices.push(deviceConfig);

        }
     
        let defaultValue = roomConfig.devices[0].value;

        return <Select key={room.id} 
        label={lang.Text(room.name)} onChange={(target) => onSelectChange(target)}
        placeholder={lang.Text(room.name)} defaultValue={defaultValue}
        data={roomConfig.devices}
    />
    }

    const validateDates = () => {
        
        let startDate: Date = new Date();
        let endDate: Date = new Date();

        startDate.setHours(addSessionForm.getInputProps('startHours').value);
        startDate.setMinutes(addSessionForm.getInputProps('startMinutes').value);

        endDate.setHours(addSessionForm.getInputProps('endHours').value);
        startDate.setMinutes(addSessionForm.getInputProps('endMinutes').value);
        setDatesOk(endDate > startDate);
    }

    const startHoursOnChange = (target: any) => {
        
        addSessionForm.setValues({ startHours: target});// =   addSessionForm.getInputProps('endHours')

        if(endTimeSelected == false) {

            let targetString: string = (+target).toString();

            //We only do this once.
            if(+target ==23){
                addSessionForm.setValues({endHours: "00"});
            }
            else {
                +target < 9? targetString = (+target + 1).toString() : (target + 1).toString();

                if((+target) < 9){
                    addSessionForm.setValues({endHours: "0" + (+target + 1).toString() });
                }
                else {
                    //addSessionForm.setValues({endHours: (target + 1).toString()});
                    addSessionForm.setValues({endHours: "19"});

                }
                     
            }

            setEndTimeSelected(true);
        }

        validateDates();
    }


    const addSession = () => {

        setAddSessionErrorMessage('');
        setLoading(true)
        
        let param: AddSessionToScheduleRequest = {
            ignoreRoomValidation: false,
            parent: props.scheduleSettings.testcenter.parent,
            testcenterId: props.scheduleSettings.testcenter.id,
            session: {
                endTime: new Date(),
                startTime: new Date(),
                rooms: []
            },
            dayOfWeek: props.addSessionDayOfWeek
        }

        param.session.startTime.setHours(+addSessionForm.getInputProps('startHours').value)
        param.session.endTime.setHours(+addSessionForm.getInputProps('endHours').value);
        param.session.startTime.setMinutes(+addSessionForm.getInputProps('startMinutes').value);
        param.session.endTime.setMinutes(+addSessionForm.getInputProps('endMinutes').value);

        /**
         * Loop through all rooms and see if they are selected 
        */
        props.scheduleSettings.testcenter.rooms.forEach(room => {
            let selectRoomConfigs: RoomDeviceCountSelection[] = roomDeviceSelections.filter(r => r.roomId == room.id);

            if(selectRoomConfigs.length > 0){
                let newRoomConfig: Schedule_Item_Session_RoomConfiguration = {
                    deviceIds: [],
                    roomId: room.id
                }

                let roomPopulated: boolean = false;
                let currentIndex: number = 0;

                while(roomPopulated == false){
                    let device: Device = room.devices[currentIndex];
                    newRoomConfig.deviceIds.push(device.id);
                    currentIndex++;
                    roomPopulated = newRoomConfig.deviceIds.length >= selectRoomConfigs[0].deviceCount;
                }

                param.session.rooms.push(newRoomConfig);
            }
        });

        fetcher<ResponseData<Schedule_Item_Session>>(GlobalAppsettings.BaseUrl + '/api/schedule/addsessiontoschedule',
        Http.POST,
        accessToken,
        param)
        .then((data: ResponseData<Schedule_Item_Session>)=>{
            if(data.isValid && data.success){
                setLoading(false);
                props.sessionAdded(data.data);
            }
            else {
                setAddSessionErrorMessage(data.messageLanguageKey);
                setLoading(false);
            }
        })
    }

     return <>
                <Box>
                    <Title order={2}><Text>{lang.Text("Add session to") + " " + lang.Text(props.addSessionDayOfWeek).toLowerCase() + "s"}</Text></Title>
                    <form onSubmit={addSessionForm.onSubmit((values) => addSession())}>
                    <Text>{createSession.contentItem?.author}</Text>
                    <Group justify="space-between">
                    <Select
                    label={lang.Text("Start hours")}
                    placeholder={lang.Text("Pick start time")} 
                    data={ hours } {...addSessionForm.getInputProps('hours')}
                />
                <Select
                    label={lang.Text("Start minutes")}
                    placeholder={lang.Text("Pick start time")} 
                    data={minutes}{...addSessionForm.getInputProps('startMinutes')}
                />
                    </Group>
                    <Group justify="space-between">
                    <Select
                    label={lang.Text("End hours")}
                    placeholder={lang.Text("Pick start time")}
                    data={hours} defaultValue={"09"}{...addSessionForm.getInputProps('endHours')}
                />
            
                <Select
                    label={lang.Text("End minutes")}
                    placeholder={lang.Text("Pick start time")} 
                    data={minutes}{...addSessionForm.getInputProps('endMinutes')}
                />
                    </Group>
             
                {
                    props.scheduleSettings.testcenter.rooms.map(r => {
                        return CreateRoomSelect(r);
                    })
                }
                <Group justify="end" mt='md'>
                    <Button  type='submit' loading={loading} justify="start" disabled={loading}>{lang.Text("Add session")}</Button>
                </Group>
                    </form>
                   
                </Box>
                {
                    addSessionErrorMessage != ''? <>
                        <Alert icon={<FontAwesomeIcon icon={duotone('shield-exclamation')} size="2x"/>}
                        title={<Title order={4}>{lang.Text("There's a problem")}</Title>} color="red" radius="md"
                        >
                            <Text>{ addSessionErrorMessage }</Text>
                        </Alert>
                    </> : null
                }
     </>
}