import { duotone, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Drawer, Button, Divider, Group, RingProgress, Stack, Text, Avatar } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { ModuleTopBar } from "../../common/components/dashboard/DashboardGrid";
import { useLanguage } from "../../common/language/Language";
import { ManageCalendarSettings } from "./components/ManageCalendarSettings";
import { useNavigate } from "react-router-dom";



export const CalendarModule = () => {
    const lang = useLanguage();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');

    const [openCalendarListSettings, setOpenCalendarListSettings] = useState<boolean>(false);

    const refresh = (force: boolean) => {

    }

    return <div className="p-4">
    <ModuleTopBar
                title={lang.Text("Calendar")}
                icon={"YC"}
                badge={<></>}
            >
        <Menu.Item onClick={() => setOpenCalendarListSettings(true)} className="text-base" 
            leftSection={<FontAwesomeIcon  icon={regular("gears")}/>}>{lang.Text('Settings')} </Menu.Item>

        <Menu.Item onClick={() => refresh(true)} className="text-base" 
            leftSection={<FontAwesomeIcon  icon={regular("rotate-right")}/>}>{lang.Text('Refresh')} </Menu.Item>

    </ModuleTopBar>


    <Stack className='mt-4 h-full'  align="stretch" justify="space-between">    
        <Group grow className="py-6 p-2" justify="start" align="start">
            <Stack >
                <Group>
                    <Text size="xs">{lang.Text('Utilization this month')}:</Text>
                        <RingProgress
                        sections={[{ value: 45, color: 'green' }]}
                        label={
                        <Text fw={700}  size="xl" className="text-center">
                        1480
                        </Text>
                        }/>
                </Group>        
            </Stack>
            <Stack justify="space-between" >
                <Group justify="space-between">
                    <Text className="font-bold">{lang.Text('Bookings Today')}:</Text>
                    <Avatar color="green"  size="md">{3}</Avatar>
                </Group>
                <Group justify="space-between">
                    <Text >{lang.Text('Open sessions Today')}:</Text>
                    <Avatar color="blue"  size="md">{24}</Avatar>
                </Group>
                {/* <Group justify="space-between">
                    <Text >{lang.Text('Sessions today')}:</Text>
                    <Avatar color="blue"  size="md">{3}</Avatar>
                </Group> */}
                <Group justify="space-between">
                    <Text c={"red"}>{lang.Text('Errors')}:</Text>
                    <Avatar color="red"  size="md">{1}</Avatar>
                </Group>
                {/* <Group justify="space-between">
                    <Text c={"red"} >{lang.Text('Result waiting')}:</Text>
                    <Avatar color="red"  size="md">{1}</Avatar>
                </Group> */}
            </Stack>
        </Group>
        <div>
        <Divider variant="dashed" className="w-full my-1 py-1" />
        <Group justify="end">
            <Button variant="subtle" className="text-base" rightSection={<FontAwesomeIcon  icon={duotone("arrow-right")}/>}
                        onClick={() => navigate('/calendar')}
                        >{lang.Text('Show Calendar')}
            </Button>
        </Group>
        </div>
    </Stack>

    <Drawer opened={openCalendarListSettings}
            position="right"
            size={isMobile? '100%' : isLargeScreen ? '900px' : '600px'}
            padding={isMobile ? 'sm' : 'sm'}
            transitionProps={{ transition: 'slide-left' }}
            onClose={() => {setOpenCalendarListSettings(false)}}
            withCloseButton= {true}
            zIndex={1200}
        >
            <ManageCalendarSettings />
            
        </Drawer>
    </div>
}
