import { useNavigate } from "react-router-dom";
import { Module } from "../../common/api/Module";
import { useLanguage } from "../../common/language/Language";
import { regular, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Stack, Loader, Divider, Button, Text, Paper, Title, Group, Badge, useMantineTheme, 
    SegmentedControl, Autocomplete, Avatar, Accordion, CopyButton, Drawer, ActionIcon, TextInput,  Container,  Center, Tabs, RingProgress,} from "@mantine/core";

import { useOs, useMediaQuery, useDisclosure } from "@mantine/hooks";
import { forwardRef, useEffect, useState } from "react";
import { GlobalSecurity } from "../../common/security/GlobalSecurity";
import { MetricsData } from "./api/MetricsApi";
import { useContent } from "../../common/content/useContent";
import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { usePubsub } from "../../common/pubsub/usePubsub";
import { AlertModule } from "../../common/components/dashboard/Alert";
import { AuthenticatedContent } from "../../common/security/components/AuthenticatedContent";
import { Footer } from "../../common/content/Footer";


import { ErrorBoundary } from "react-error-boundary";

import { Http, fetcher } from "../../common/Fetcher";
import { GlobalAppsettings } from "../../GlobalSettings";

import { AreaChart } from "@tremor/react";
import { ModuleTopBar } from "../../common/components/dashboard/DashboardGrid";

const chartdata = [
    {
      date: "Jan 23",
      Exams: 890,
      Refunds: 38,
    },
    {
      date: "Feb 23",
      Exams: 756,
      Refunds: 103,
    },
    {
      date: "Mar 23",
      Exams: 1122,
      Refunds: 194,
    },
    {
      date: "Apr 23",
      Exams: 1270,
      Refunds: 108,
    },
    {
      date: "May 23",
      Exams: 1475,
      Refunds: 292,
    },
    {
      date: "Jun 23",
      Exams: 2129,
      Refunds: 126,
    },
  ];
  
  const dataFormatter = (number: number) => {
    return "" + Intl.NumberFormat("us").format(number).toString();
  };




export const MetricsModule = () => {
    const lang = useLanguage();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    const refresh = () => {

    }

    return  (<div className="p-4">
        <ModuleTopBar
            title={"Metrics"}
            icon={"ST"}
            iconColor={"yellow"}
            badge={<></>}
        >
    {/* {data?.result !== undefined && data?.result.accountId ?
    <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("link")}/>}
        rightSection={<FontAwesomeIcon  icon={duotone("arrow-up-right-from-square")}/>}
        onClick={() => window.open("https://dashboard.stripe.com/" + data.result.accountId + "/dashboard","_new")}
        >{lang.Text('Stripe Dashboard')}</Menu.Item>: <></>} */}

    <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("rotate-right")}/>}
            onClick={() => refresh()}>{lang.Text('Refresh')}</Menu.Item> 
</ModuleTopBar>
        <Group grow>
            {isLoading ? <Center><Loader variant="dots" /></Center> : <>
            <AreaChart
            className="h-48"
            data={chartdata}
            index="date"
            categories={["Exams", "Refunds"]}
            //   showLegend={false}
            colors={["yellow", "gray"]}
            valueFormatter={dataFormatter}
            showXAxis={true}
            showGridLines={false}
            startEndOnly={true}
            showYAxis={false}
            />
            
            </>}
            <Stack className='mt-4'  align="stretch" justify="space-between">    
            
              <Group 
              // className="w-26 h-26"
              >
                <RingProgress
                    sections={[{ value: 89, color: 'yellow' }]}
                    label={
                    <Text fw={700}  size="xl" className="text-center">
                       1548
                    </Text>
                    }/>
                    <RingProgress
                    sections={[{ value: 40, color: 'gray' }]}
                    label={
                    <Text fw={700}  size="xl" className="text-center">
                       15
                    </Text>
                    }/>
                </Group>
                
            <Divider variant="dashed" className="w-full my-1" />
            <Group justify="end">
                <Button variant="subtle" className="text-base" rightSection={<FontAwesomeIcon  icon={duotone("arrow-right")}/>}
                            onClick={() => navigate('/metrics')}
                            >{lang.Text('See more metrics')}
                </Button>
            </Group>
            </Stack>
        </Group>
    </div>)    
}