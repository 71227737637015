import { duotone, light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Group, Badge, Divider, Timeline, Button, Drawer, Title, Text, Loader, Stepper, CopyButton } from "@mantine/core";
import { useDisclosure, useMediaQuery, useOs } from "@mantine/hooks";
import { useState, useEffect } from "react";
import useSWR from "swr";
import { fetcher, Http } from "../../../common/Fetcher";
import { ResultBase } from "../../../common/api/common";
import { useLanguage } from "../../../common/language/Language";
import { GlobalSecurity } from "../../../common/security/GlobalSecurity";
import { Onboarding, Step } from "../api/ProctorApi";
import { DateTime } from "luxon";

export const OnBoardingSteps = (props: {onboarding : Onboarding, vertical: boolean}) => {
    
    const getActiveState = () => {
        let active = 0;
        if (props.onboarding?.steps !== undefined && props.onboarding?.steps.length > 0) {
           props.onboarding.steps.forEach((step, key) => {
                if (step.isCompleted) {
                    active = key + 1;
                }
           });
        }
        return active;
    }
    
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    
    const getStepColor = (step: Step) => {
        if (step.isCompleted) {
            return 'green';
        }
        if (step.isProcessing) {
            return 'violet.5';
        }
        if (step.isValid === false && step.reason !== undefined && step.reason !== '') {
            return 'red';
        }
        return 'gray.6';
    }

    useEffect(() => {
        setActive(getActiveState());
    }, [props.onboarding]);

    return (
      <>
        <Stepper className="min-h-[110px] mt-6" active={active} orientation={props.vertical ? "vertical": "horizontal"}
            iconSize={24}>
            {props.onboarding?.steps.map((step, key) => {
            return (
            <Stepper.Step key={key} label={step.description} 
                description={props.vertical ?  step.reason : ''}
                color={getStepColor(step)} >
                {step.reason}
            </Stepper.Step>
            )
        })}
        </Stepper>
  
        {/* <Group justify="center" mt="xl">
          <Button variant="default" onClick={prevStep}>Back</Button>
          <Button onClick={nextStep}>Next step</Button>
        </Group> */}
      </>
    );
  }


export const OnboardingWidget = (props: {id: string |undefined}) => {
    const lang = useLanguage();
    const {accessToken} = GlobalSecurity();
    const [showPanel, { open: openPanel, close: closePanel }] = useDisclosure(false);
    const os = useOs();
    const isMobile = os === "ios" || os === "android";
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1220px)');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const {data } = useSWR<ResultBase<Onboarding>>(
        accessToken!==undefined && props.id!==undefined ? 
        ["/api/proctor/getonboarding?id=" + props.id, accessToken] : null,
        () => fetcher("/api/proctor/getonboarding?id=" + props.id, Http.GET, accessToken), {
            keepPreviousData: false,
            refreshInterval: 5000,
            revalidateOnFocus: true,
            onError: (err) => {
                console.error(err);    
            },
        },            
    );

    const [current, setCurrent] = useState<Onboarding | undefined>(undefined);

    useEffect(() => {
        console.info("OnboardingWidget", data);
        if (data !== undefined && data.success) {
            
            if (data.result.isCompleted) {
                data.result.status = 2;
            }
            
            setCurrent(data.result);

            setError(false);
        }
        else
        {
            if (!data?.success)
            {
                setError(true);
                setErrorMessage(lang.Text(data?.errorMessage));
            }
        }
    }, [data]);

    const getActiveStep = () => {
        let active = 0;
        if (current?.steps !== undefined && current?.steps.length > 0) {
           current.steps.forEach((step, key) => {
                if (step.isCompleted) {
                    active = key + 1;
                }
           });
        }
        return active;
    }

    const getStatusColor = (status?: number) => {
        switch (status) {
            case 0: return 'gray.6';
            case 1: return 'violet.5';
            case 2: return 'green';
            case 3: return 'red';
            default: return 'gray.6';
        }
    }

    const getStatusText = (status?: number) => {
        switch (status) {
            case 0: return lang.Text('Not started');
            case 1: return lang.Text('In progress');
            case 2: return lang.Text('Finished');
            case 3: return lang.Text('Error');
            default: return lang.Text('Unknown');
        }
    }

    return (<>
    {props.id !==undefined ?
    <Card className="min-h-[200px] text-sm " withBorder>
        <Group justify="space-between" >
            <Text className="font-bold">{lang.Text('Onboarding')}</Text>
            <Badge color={getStatusColor(current?.status)}>{getStatusText(current?.status)}</Badge>
        </Group>
        <Divider variant="dashed" className="my-2" />
        {error ? <Text color="red">{errorMessage}</Text>: <>
            <OnBoardingSteps onboarding={current!} vertical={false}/>
            <Group justify="end">
                {current?.isCompleted ? <></> : <Loader type="dots"/>}
                <Button variant="subtle" color="primary" size="sm" onClick={openPanel}>
                    <FontAwesomeIcon  icon={light('list-dots')} size="lg"/>
                </Button>
            </Group>
        </>}
    </Card>: <></>}

    {/* panel */}
    <Drawer opened={showPanel} position="right"
        title={<Title order={3} className="mt-2">{lang.Text('Onboarding')}</Title>}
        onClose={closePanel} withCloseButton size={isMobile || isSmallScreen ? '100%': '650px' }>
        
        {props.id ? 
                 <CopyButton value={props.id}>
                        {({ copied, copy }) => (<Group >
                            <Text size="xs" color="dimmed">{props.id}</Text>
                            <Button size="xs" variant="subtle" color={copied ? 'teal' : 'blue'} onClick={copy}>
                                 {copied ? 'Copied!' : 'Copy id'}
                            </Button></Group>
                        )}
                    </CopyButton> : <></>}
        <Divider variant="dashed" className="my-4" />

        <Card  radius="md" className="min-h-[200px] text-sm p-4" withBorder>
            <Card.Section className="p-4">
                <Title order={4}>{lang.Text('Booking')}</Title>
            </Card.Section>
            
             
            <Text>{lang.Text('Name: ')}<Text span fw={700} inherit>{current?.booking.name}</Text></Text>
            <Text>{lang.Text('Email: ')}<Text span fw={700} inherit>{current?.booking.email}</Text></Text>
            <Text>{lang.Text('Code: ')}<Text span fw={700} inherit>{current?.booking.code}</Text></Text>
            <Group>
                <Text>{lang.Text('Booking: ')}</Text>                
                <Button variant="subtle" 
                    onClick={() => window.open(current?.booking.onboardingLink, "_new")}
                    rightSection={<FontAwesomeIcon icon={regular('external-link-alt')} size="sm"/>}
                    >{lang.Text('Link')}</Button>
            </Group>

            {current?.booking.id ? 
            <CopyButton value={current?.booking.id}>
                {({ copied, copy }) => (<Group >
                    <Text size="xs" color="dimmed">{current?.booking.id}</Text>
                    <Button size="xs" variant="subtle" color={copied ? 'teal' : 'blue'} onClick={copy}>
                            {copied ? 'Copied!' : 'Copy booking id'}
                    </Button></Group>
                )}
            </CopyButton> : <></>}                   


        </Card>
        <Divider variant="dashed" className="my-4" />
        <Group >
                <Text>{lang.Text('Started:')}</Text>
                {current?.createdDate && <Text className="font-bold">{DateTime.fromISO(current?.createdDate).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</Text>}
            </Group>
        <Group grow className="px-4">
            
            <OnBoardingSteps onboarding={current!} vertical={true}/>
            <Card withBorder radius="md" className="min-h-[230px]">

            </Card>
        </Group>
        
    </Drawer>
    </>);
}
