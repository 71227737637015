import * as React from 'react'
import { useLanguage } from '../../../common/language/Language'
import { Testcenter } from '../api/Testcenter';
import { Box, Group, Title, Text } from '@mantine/core';

export interface ManageCalendarSettingsProps {
    testcenter?: Testcenter;
}

export const ManageCalendarSettings = (props: ManageCalendarSettingsProps) => {

    const lang = useLanguage();

    console.log(props.testcenter?.scheduleDefinition);

    return(<>
        <Box>
           <Title order={2}>{lang.Text("Calendar Settings")}</Title> 
        </Box>
    </>)
}