

import { useContext, useEffect, useReducer, useState } from 'react';

// Packages imports
import { Space, Title, Text,  Image,  Stack, Grid,  TypographyStylesProvider, Button, ActionIcon, Indicator, Drawer, Container, ScrollArea, Group, BackgroundImage, CloseButton } from '@mantine/core';

// Components imports
import { LanguageContext } from '../../common/language/Language';
import { useContent } from '../../common/content/useContent';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { usePubsub, useSubscribe } from '../../common/pubsub/usePubsub';
import { ProductItem } from './api/Products';
import { useForceUpdate, useLocalStorage, useMediaQuery} from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { appInsights } from '../../GlobalSettings';


export const ShoppingCart = (props: {onCheckout?: () => void}) => {

    const lang = useContext(LanguageContext);

    const [viewShoppingcart, setViewShoppintCart] = useState(false);
    const [itemsCount, setItemsCount] = useState(0);
    const forceUpdate = useForceUpdate();
    const app = usePubsub();

    const isMobile = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');
    
    const [total, setTotal] = useState(0);
    const [items, setItems] = useLocalStorage<ProductItem[]>(
        { key: "yc-enterprise-shoppingcart", defaultValue: new Array<ProductItem>(),
        getInitialValueInEffect : false          
    });

    const [currency, setCurrency] = useState<string>('GBP');

    const navigate = useNavigate();

    const info = useContent({contentID: '5zZNbBnVk3X2xEkwqCL6Av', locale: lang.locale(), preview: false});
    
    useEffect(() => {
        if (items!==undefined && items.length>0)
        {
            setItems(items);
            setItemsCount(items.length);
            updateTotal(items);
            if (items.length>0) {
                setCurrency(items[0].currency);
            }
            forceUpdate();
        } 
    }, [items]);

    const updateTotal = (productItems: ProductItem[]) => {
        var total = 0;
        productItems?.forEach(p => { total = total + (p.count * p.price); });
        setTotal(total);
        if (productItems.length>0) {
            setCurrency(productItems[0].currency);
        }
    }

    useSubscribe('cart.add', (msg, product:  ProductItem) => {
        if (product!==undefined) {
            let productItems = items;
            if (productItems !== undefined) {
            
                let find =productItems.find((p: ProductItem) => p.sys.id === product.sys.id);
                if (find === undefined) {
                    product.count = 1;
                    productItems?.push(product as ProductItem);
                }
                else {
                    find.count++;
                }
                setItems(productItems);
                updateTotal(productItems);
        
                setItemsCount(productItems.length);
                
                //first item added - open cart
                //if (productItems.length===1) {
                setViewShoppintCart(true);
                //}
            }
            appInsights.trackEvent({
                name: 'Add to cart',
                properties: {
                  product: product,
                }
              });
        }
    });

    useSubscribe('cart.open', (msg, data) => {
        if (data!==null) {
            setViewShoppintCart(data);
        }
        else {
            setViewShoppintCart(true);
        }
    });

    const updateItem = (item: ProductItem) => {
        let productItems = items;
        if (productItems !== undefined) {
            let find =productItems.find((p: ProductItem) => p.sys.id === item.sys.id);
            if (find !== undefined) {
                find.count = item.count;
                if (item.count===0) {
                    productItems.splice(productItems.indexOf(find), 1);
                }
            }
        
            setItems(productItems);
            updateTotal(productItems);

            setItemsCount(productItems.length);
        }
        app.publish('cart.update');
        forceUpdate();
    }

    const getOnSale = (onSale?: string): boolean => {
        if (onSale!==undefined && onSale!==null && onSale.length>0) {
            return false;
        }
        return true;
    }

    const checkout = () => {
        try {
            if(props!==undefined && props.onCheckout!==undefined  && props.onCheckout!==null) {
                props.onCheckout();
                setViewShoppintCart(false);
            }
            else{
                navigate('/checkout');
            }
        } catch (error) {
            console.error(error);
        }
    }

    const hasItems =  items && items.length > 0;

    return (<> 
     {/* Shopping Cart button*/}
     <>
    <Indicator inline label={itemsCount} 
        disabled={!hasItems} 
        color="pink" size={20} 
        // overflowCount={10}
        >
        <ActionIcon variant='transparent' size={'xl'}
        onClick={() => setViewShoppintCart(true)}>
            <FontAwesomeIcon icon={duotone('cart-shopping')} size="2x"/>
        </ActionIcon>
    </Indicator>
    </>
    {/* Shopping Cart Drawer*/}
    <Drawer
            opened={viewShoppingcart} 
            position="right"
            size={isMobile ? '100%' : isLargeScreen? '600px' : '600px'}
            padding={isMobile ? 'sm' : 'md'}
            transitionProps={{ transition: 'slide-left' }}
            onClose={() => {
               setViewShoppintCart(false);
            } }
            withCloseButton={false}
            zIndex={1200}    
            title={ <Title order={2} className="pb-4">{info.contentItem?.caption}</Title> }
            >
     <CloseButton 
                style={{position: 'fixed', right: '6px', top: '6px', zIndex: '400' }}
                onClick={() => setViewShoppintCart(false)} title="Close popover" size="xl" iconSize={20} />
      {/* Cart */}
      <Stack justify="space-between"  className='h-[78vh] ' >
        <ScrollArea  offsetScrollbars>
        {hasItems ? 
            <Stack className='pt-2'> 
                {items.map((item: ProductItem, index: number) => (
                <Grid className="">
                    <Grid.Col span={2}>
                        <Image radius="md" src={item.media.url}/>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Text>
                            <span className='font-bold pr-2'>{item.count} x</span>
                            {item.caption}</Text>
                        <Group>
                        <ActionIcon variant='subtle' size="xl" className=''
                                onClick={() => {
                                    item.count--;
                                    updateItem(item);
                                }}>
                                <FontAwesomeIcon icon={duotone('minus-circle')} size="2x"/>
                        </ActionIcon>
                        <ActionIcon variant='subtle' size="xl" className=''
                                onClick={() => {
                                    item.count++;
                                    updateItem(item);
                                }}>
                                <FontAwesomeIcon icon={duotone('plus-circle')} color="" size="2x"/>
                        </ActionIcon>
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Indicator disabled={getOnSale(item.onSale)} 
                        color="red" style={{zIndex: '500'}}
                        label={item.onSale} inline size={16} position='bottom-center'>
                        <Text size={'sm'}>
                            {lang.Text("Price")}:
                            <Text inherit component="span" className='pl-1 text-xl'>
                            {item.price*item.count} </Text> 
                            <Text inherit component="span" color={'dimmed'}>{item.currency}</Text>
                        </Text>
                        </Indicator>
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Group >
                            <ActionIcon color="red" size="md" className='ml-3'
                                    onClick={() => {
                                    item.count = 0;
                                    updateItem(item);
                                }}>
                                <FontAwesomeIcon icon={duotone('trash')} size="1x"/>
                            </ActionIcon>
                        </Group>
                    </Grid.Col> 
                </Grid>
                ))}
                <Space/>
               
            </Stack>
            :<Stack>
                <Text>{lang.Text('Your Shopping Cart is empty.')}</Text>
            </Stack>} 
        </ScrollArea>
            <Stack>
                {hasItems ? <>
                <div className="p-4">
                    <Group justify="end">
                        <Text>{lang.Text('Total amount')}:</Text>
                        <Text size={'xl'}>{total}
                            <Text inherit component="span" className='text-xs px-2' color={'dimmed'}>{currency}</Text>
                        </Text>
                    </Group>
                </div>
                <div className="py-2">
                {info.contentItem?.copy ?
                    <TypographyStylesProvider >
                    <ReactMarkdown className='text-gray text-sm' key={"info.contentItem?.copy"} 
                                children={info.contentItem?.copy}  
                                remarkPlugins={[remarkGfm]}
                                />
                    </TypographyStylesProvider>:<></>}
                </div>
                </>:<></>
                }
                <Group className='py-2' justify="space-between">    
                    <Button variant="outline" onClick={()=>setViewShoppintCart(false)}>{lang.Text('Close')}</Button>
                    {hasItems ? <Button variant="filled" color={'pink'} 
                        onClick={() => checkout()}
                        >{lang.Text('Checkout')}</Button>:<div></div>}
                </Group>
            </Stack>
        </Stack>  
      </Drawer>
      
    </>)
}


