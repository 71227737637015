import { useContext } from 'react';

// Packages imports
import { Affix, Alert, Button, Transition, Text } from '@mantine/core';

// Contexts imports
import { useLanguage } from './common/language/Language';

import { PoweredBy } from './common/components/PoweredBy';

import { GlobalHeader } from './common/components/navigation/GlobalHeader';

import { ContentRoutes } from './common/content/ContentRoutes';
import { ContentMenu } from './common/content/ContentMenu';

import { UserMenu } from './common/security/User';

import { GlobalSecurity } from './common/security/GlobalSecurity';
import { Route, Routes} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useOs, useWindowScroll } from '@mantine/hooks';

import { NotFound } from './pages/NotFound';
import { Landing } from './pages/Landing';
import { Store } from './modules/ecommerce/Store';

import { CheckoutPage } from './pages/CheckoutPage';
import { OnboardingPage } from './pages/MyBookingPage';
import InvitePage from './pages/Invite';

import { Dashboard } from './pages/Dashboard';

import ScrollToTop from './common/content/components/ScrollToTop';
import { ProctoringPage } from './modules/proctoring/ProctoringModule';
import { PaymentsPage } from './modules/payment/PaymentModule';
import { PaymentOnboardingPage } from './modules/payment/PaymentOnboarding';
import { ManageCalendarSettings } from './modules/TestcenterManagement/components/ManageCalendarSettings';
import { ManageSchedulePage } from './modules/TestcenterManagement/pages/ManageSchedule';

import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
    const lang = useLanguage();
    const os = useOs();
    const isMobile = os === "ios" || os === "android";
    
    const [scroll, scrollTo] = useWindowScroll();

    return (<>
        <ScrollToTop/>    
        <div>
        <GlobalHeader cta={<>
            <UserMenu/>
            {/* <ContentMenu contentId='3q5IO3nkSfso3uoabqK3e0' /> */}
            <Button 
                radius={'xl'} 
                    //variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}
                leftSection={<FontAwesomeIcon icon={duotone('bags-shopping')} size="2x"/>}
                className="font-light mx-2"
                onClick={() => window.open('/store',"_self")}
                >{lang.Text('Store')}
            </Button>
        </>}>
            <ContentMenu contentId='2sdEHRYChCjzXOO9EzoVVK' dark={true} bgColor='bg-purple-700'/>
           {/* 0 <ContentMenu contentId='7jHlWlryf1MIaWCuS7mXib' dark={true} bgColor='bg-purple-700'/> */}
            <ContentMenu contentId='261QAQnBnAe7AtRg125RBB' dark={true} bgColor='bg-purple-700'/>
        </GlobalHeader>

        {/* Content */}
        <div className=''> 
            <SentryRoutes>                
                <Route path="/" element={<Landing />} />
                <Route path="dashboard" element={<Dashboard />} />
                
                <Route path="store" element={<Store filter="portal" />} />
                <Route path="checkout" element={<CheckoutPage />} />
                <Route path="booking" element={<OnboardingPage />} />
                <Route path="calendar" element={<ManageSchedulePage />} />
                
     
                <Route path="invite/:invitationId/:orgId" element={<InvitePage /> } />
                
                {/* <Route path="manageOrganisation" element={<ManageOrganisation />} />
                <Route path="testcenters" element={<MyTestcenters /> } />
                <Route path="testcenter" element={<TestcenterPage /> } /> 
                <Route path="users" element={<ManageUsersPage />} />
                <Route path="modules" element={<ModulesPage />} />
                */}
                
                <Route path="proctoring" element={<ProctoringPage /> } />
                <Route path="payments" element={<PaymentsPage /> } />

                <Route path="payments-onboarding/:id" element={<PaymentOnboardingPage /> } />

                {ContentRoutes({contentType:'page', locale:lang.locale(), preview:false})}
                {ContentRoutes({contentType:'news', locale:lang.locale(), preview:false})}

                <Route path="*" element={<NotFound />} />
            </SentryRoutes>
            </div>
            
            <PoweredBy 
                useWaves
                wavesPageUrl='/'
            />

            {isMobile? <></>:
                <Affix position={{ bottom: 70, right: 10 }} zIndex={100}>
                    <Transition transition="slide-up" mounted={scroll.y > 0}>
                    {(transitionStyles) => (
                        <Button
                            style={transitionStyles}
                            variant="outline"
                            onClick={() => scrollTo({ y: 0 })}
                        >
                        <FontAwesomeIcon icon={duotone('arrow-up')}/>
                        </Button>
                    )}
                    </Transition>
                </Affix>
            }
        </div>
        </>
    );
}

export default App;
