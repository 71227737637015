import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
// import {
//   ChevronDownIcon,
//   ChevronLeftIcon,
//   ChevronRightIcon,
//   ClockIcon,
//   EllipsisHorizontalIcon,
// } from '@heroicons/react/20/solid'

import { DateTime, Settings } from 'luxon'
import { Accordion, ActionIcon, Anchor, Avatar, Badge, Box, Button, Card, Image, CloseButton, CopyButton, Divider, Drawer, Grid, Group, Menu, MenuLabel, NumberInput, Popover, ScrollArea, SimpleGrid, Stack, Switch, Table, Text, TextInput, Title, Tooltip, rem, Loader, Center } from '@mantine/core'
import { days } from '../api/days'
import { ResultData } from '../../../common/api/common'
import { Schedule_Calendar, Schedule_Calendar_Day_SessionItem } from '../api/Schedule'
import useSWR from 'swr'
import { Http, fetcher } from '../../../common/Fetcher'
import { GlobalSecurity } from '../../../common/security/GlobalSecurity'
import  luxon from 'luxon';
import { useLanguage } from '../../../common/language/Language'
import { useMediaQuery } from '@mantine/hooks'

import { duotone, light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { hasLength, isEmail, isInRange, isNotEmpty, matches, useForm } from '@mantine/form'


//const selectedDay = days.find((day) => day.isSelected)

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export interface CalendarWeekViewProps {
  
}


const sessions = [
  {
    id: '1',
    start: '07.00',
    end: '07.30',
    description: 'Session 1',
    bookings: []
  },
  {
    id: '2',
    start: '07.30',
    end: '08.00',
    description: 'Session 2',
    bookings: [
      {
        id: '1',
        firstname: 'John',
        lastname: 'Doe',
        neph: '123456789',
        code: '5447342',
        email: '',
      }
    ]
  },
  {
    id: '3',
    start: '08.00',
    end: '08.30',
    description: 'Session 3',
    bookings: []
  },
  {
    id: '4',
    start: '08.30',
    end: '09.00',
    description: 'Session 4',
    bookings: []
  },
  {
    id: '5',
    start: '09.00',
    end: '09.30',
    description: 'Session 5',
    bookings: []
  }
  ,
  {
    id: '6',
    start: '09.30',
    end: '10.00',
    description: 'Session 6',
    bookings: []
  }
];

export const CalendarWeekView = (props: CalendarWeekViewProps) => {

  const [ shouldFetchSchedule, setShouldFetchSchedule] = useState<boolean>(true);
  const [events, setEvents] = useState<Schedule_Calendar_Day_SessionItem[]>([]);
  
  const [ selectedSession, setSelectedSession] = useState<Schedule_Calendar_Day_SessionItem | undefined>(undefined);
  const [ openSelectedSessionDrawer, setOpenSelectedSessionDrawer ] = useState<boolean>(false);
  const [ openBookingsDrawer, setOpenBookingsDrawer ] = useState<boolean>(false);

  const [checkSnu, setCheckSnu] = useState<boolean>(false);

  const [checkEligble, setCheckEligble] = useState<boolean>(false);

  const {accessToken} = GlobalSecurity();
  const  lang  = useLanguage();


  const form = useForm({
    initialValues: {
      firstname: '',
      lastname: '',
      neph: '',
      email: '',
      favoriteColor: '',
      age: 18,
    },
    
    validate: {
      firstname: hasLength({ min: 2, max: 10 }, 'Name must be 2-10 characters long'),
      lastname: hasLength({ min: 2, max: 10 }, 'Name must be 2-10 characters long'),
      neph: isNotEmpty('Enter NEPH'),
      email: isEmail('Invalid email'),
      favoriteColor: matches(/^#([0-9a-f]{3}){1,2}$/, 'Enter a valid hex color'),
      age: isInRange({ min: 18, max: 99 }, 'You must be 18-99 years old to register'),
    },
  });

  //For the session details drawer
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isLargeScreen = useMediaQuery('(min-width: 1200px)');

  const viewport = useRef<HTMLDivElement>(null);

  const [ openAddCodeDeLaRoute, setOpenAddCodeDeLaRoute] = useState<boolean>(false);
 

  const container = useRef(null)
  const containerNav = useRef(null)
  const containerOffset = useRef(null)

  const getEndDate = () => {
    let endDate: DateTime = DateTime.now();

    let endDateIsReset: boolean = false;

    endDateIsReset = endDate.weekday == 7;

    while(endDateIsReset == false){
        
      endDate = endDate.plus({days: 1});
      endDateIsReset = endDate.weekday == 7;
    }

    return endDate;
    
  }

  const getStartDate = () => {
    
    let startDate: DateTime = DateTime.now();
    
    let startDateIsReset: boolean = false;

    startDateIsReset = startDate.weekday == 1;

    while(startDateIsReset == false){
      startDate = startDate.minus({days: 1});

      startDateIsReset = startDate.weekday == 1;
    }

    return startDate;
  } 

  // //Loading the events for the month
  // const { data: data, mutate: mutate } = useSWR<ResultData<Schedule_Calendar>>(
  //    shouldFetchSchedule == true ? ["/api/schedule/getscheduleget?TestcenterId=92a87149-fff4-4d57-8c00-322ea8b06b12&ParentId=f81868dc-833e-4840-bb24-a7cf5af82817&startDate=" + getStartDate().toISODate() + "&endDate=" + getEndDate().toISODate(), accessToken] : null,
  //   () => fetcher("/api/schedule/getscheduleget?TestcenterId=92a87149-fff4-4d57-8c00-322ea8b06b12&ParentId=f81868dc-833e-4840-bb24-a7cf5af82817&startDate=" + getStartDate().toISODate() + "&endDate=" + getEndDate().toISODate(), Http.GET, accessToken), {
  //     keepPreviousData: true,
  //     refreshInterval: 0,
  //     revalidateOnFocus: true,
  //     onError: (err) => {
  //         console.error(err);    
  //     },
  //   }
  // )

    //Loading the events for the month
    const { data: data, mutate: mutate } = useSWR<ResultData<Schedule_Calendar>>(
      shouldFetchSchedule == true ? ["/api/schedule/getweeklyschedule?organisationId=92a87149-fff4-4d57-8c00-322ea8b06b12&parentId=f81868dc-833e-4840-bb24-a7cf5af82817&year=" + 2023 + "&weekOfYear=" + DateTime.now().weekNumber, accessToken] : null,
     () => fetcher("/api/schedule/getweeklyschedule?organisationId=92a87149-fff4-4d57-8c00-322ea8b06b12&ParentId=f81868dc-833e-4840-bb24-a7cf5af82817&year=" + 2023 + "&weekOfYear=" + DateTime.now().weekNumber, Http.GET, accessToken), {
       keepPreviousData: true,
       refreshInterval: 0,
       revalidateOnFocus: true,
       onError: (err) => {
           console.error(err);    
       },
     }
   )

  useEffect(() => {

    console.log("data:");
    console.log(data);

    if(data !== undefined && data.success && data.data !== undefined && data.data.days !== undefined && data.data.days !== null ) {
      
      let eventsData: Schedule_Calendar_Day_SessionItem[] = [];

      data.data.days.forEach(day => {
        if(day.sessions !== undefined && day.sessions != null) {
          day.sessions.forEach(session => {
            eventsData.push(session);
          })  
        }
      });

      setEvents(eventsData);
      
      setShouldFetchSchedule(false);

      viewport.current!.scrollTo({ top: 750, behavior: 'smooth' });
    }
    else {

    }
  }, [ data ])



  const GetWeekdayDate = (dayOfWeek: number) => {

    var nowDate = DateTime.now();
    var dayOfWeekDate = nowDate.minus({day: 0});
    
    //console.log(nowDate.weekday);

    if(dayOfWeekDate.weekday === dayOfWeek) {
      return dayOfWeekDate;
    }

    while(dayOfWeekDate.weekday !== dayOfWeek) {
      

      nowDate.weekday > dayOfWeek? dayOfWeekDate = dayOfWeekDate.minus({day: 1}) : dayOfWeekDate = dayOfWeekDate.plus({day: 1});
     // console.log(dayOfWeekDate);

      if(dayOfWeekDate.weekday === dayOfWeek) {
        return dayOfWeekDate;
      }
    }

    return dayOfWeekDate;
  }

  const renderWeekViewHeaderDay = (dayOfWeek: number) => {
    let todaysDayOfMonth = DateTime.now().day;

    let dayOfWeekDate :DateTime =  GetWeekdayDate(dayOfWeek);
    var ret = <></>;

    if(todaysDayOfMonth === dayOfWeekDate.day) {
      ret =
         <div className="flex items-center justify-center py-3">
                <span className="flex items-baseline">
                  {dayOfWeekDate.weekdayShort} {' '}
                  <span className="ml-1.5 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white">
                    {/* { GetWeekdayDate(dayOfWeek) } */}
                    { dayOfWeekDate.day}
                  </span>
                </span>
              </div>
    }
    else 
    {
    ret =
    <div className="flex items-center justify-center py-3">
      <span>
        {dayOfWeekDate.weekdayShort} <span className="items-center justify-center font-semibold text-gray-900">{dayOfWeekDate.day.toString()}</span>
      </span>
    </div>
    }

    return ret;
  }

  const onCalendarEventClick = (session: Schedule_Calendar_Day_SessionItem) => {
    
      setSelectedSession(session);
      setOpenSelectedSessionDrawer(true);

  }

  const RenderWeeklyCalendarItem = (session: Schedule_Calendar_Day_SessionItem) => {

    // console.log("Date:");
    // console.log(session.startTimeUtc);
    let startDateTime: DateTime = DateTime.fromISO(session.startTimeUtc.toString()).toLocal();

    let endDateTime: DateTime = DateTime.fromISO(session.endTimeUtc.toString()).toLocal();

   // console.log(startDateTime);

   //console.log(session.startTimeUtc.getHours);
    let startRow: string = "";
    startRow = ((startDateTime.hour * 12) + 2).toString();

    let spanLength: string = "";
    spanLength = (((endDateTime.hour - startDateTime.hour + 2) * 12)).toString();

//    console.log("Span:" + spanLength);

    let eventLabel: string = lang.Text("No room");

    if(session.roomNames.length > 0 && session.roomNames[0] !== null && session.roomNames[0] !== '') {
      eventLabel = session.roomNames;
    }

    let startTimeHourString: string = startDateTime.hour < 10? "0" + startDateTime.hour : startDateTime.hour.toString();
    let startTimeMinuteString: string = startDateTime.minute < 10? "0" + startDateTime.minute : startDateTime.minute.toString();


    let isPastTime = startDateTime < DateTime.utc().plus({days: -1});
    


    return   <li className={"relative mt-px hidden sm:col-start-" + startDateTime.weekday + " sm:flex"} 
      style={{ gridRow: '' + startRow + '/ span ' + spanLength + '' }}
      
      onClick={() => {
          onCalendarEventClick(session);
        }}
      >
     <Popover
      width={500}
      opened={false}
      position="right"
      offset={0}      
    >
      <Popover.Target>
      <div
      className={isPastTime ? 
          "group absolute inset-1 flex flex-col overflow-y-hide rounded-lg bg-gray-100 p-2 text-xs leading-5 hover:bg-gray-200"
          :
          "group absolute inset-1 flex flex-col overflow-y-hide rounded-lg bg-blue-100 p-2 text-xs leading-5 hover:bg-blue-200"
      }
    >
      
      <p className="text-gray-500 group-hover:text-gray-700">
        <time dateTime="2022-01-15T10:00">{startTimeHourString + ":" + startTimeMinuteString}</time>
        
      </p>
      <div className='font-bold pb-2'>{eventLabel}</div>

      <Group align='start' style={{minHeight: '55px'}} justify='space-between'>
      
      <Badge variant="filled" color={isPastTime ? 'lightgray': 'blue'}> {session.bookings.length}/{session.totalSlots}</Badge>
      {/* {lang.Text('Sessions')} */}
      

      </Group>
      </div>
      {/* <p className="order-1 font-semibold text-gray-700">{eventLabel}</p> */}
    
      </Popover.Target>
      <Popover.Dropdown className='h-56 w-56 rounded shadow-lg'>
        <div>


        </div>
      </Popover.Dropdown>
    </Popover>
    
   
  </li>
  }

    // useEffect(() => {
    //   // Set the container scroll position based on the current time.
    //   const currentMinute = new Date().getHours() * 60

    //   if(container !== undefined && container !== null
    //     && container.current !== undefined && container.current !== null
    //     && container.current.scrollTop !== undefined && container.current.scrollTop !== null) {
    //     container.current.scrollTop =
    //     ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
    //       currentMinute) /
    //     1440
    //   }
     
    // }, [])

    //let isPastTime = selectedSession?.startTimeUtc.getDate() < DateTime.utc().plus({days: -1});

    let isPastTime = selectedSession !== undefined && selectedSession.startTimeUtc < DateTime.utc().plus({days: -1});


    

    let startTimeString = '07:00';
    let endTimeString = '10:00';
    
   
    
    return (<>
    <Grid >
    <Grid.Col span={openSelectedSessionDrawer ? 8 : 12}>
    <div ref={container} className="isolate flex flex-auto flex-col overflow-auto bg-white">
        <div 
        // style={{ width: '165%' }} 
          className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
          >
            {/* <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                M <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">10</span>
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                T <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">11</span>
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                W{' '}
                <span className="mt-1 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white">
                  12
                </span>
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                T <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">13</span>
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                F <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">14</span>
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                S <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">15</span>
              </button>
              <button type="button" className="flex flex-col items-center pb-3 pt-2">
                S <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">16</span>
              </button>
            </div> */}
            <Group justify='space-between'>
              <Button variant='subtle' className='w-16'><FontAwesomeIcon icon={light('arrow-left')} size='2xl'/></Button>
              <Button variant='subtle' className='w-16'><FontAwesomeIcon icon={light('arrow-right')} size='2xl' /></Button>
            </Group>
            <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
            <div className="col-end-1 w-14 max-h-[400px]" />
              {
                renderWeekViewHeaderDay(1)
              }
              {
                renderWeekViewHeaderDay(2)
              }
              {
                renderWeekViewHeaderDay(3)
              }
              {
                renderWeekViewHeaderDay(4)
              }
              {
                renderWeekViewHeaderDay(5)
              }
              {
                renderWeekViewHeaderDay(6)
              }
              {
                renderWeekViewHeaderDay(7)
              }
            </div>
            
          </div>
          <ScrollArea className="" h={'80vh'} viewportRef={viewport}>
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
              >
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    1AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    3AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    5AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    9AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    11AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    1PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    3PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    5PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    9PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    11PM
                  </div>
                </div>
                <div />
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
              >

                {events.map(event => {
                  return RenderWeeklyCalendarItem(event);
                })}
             
              </ol>
            </div>
          </div>
          </ScrollArea>
        </div>
      </div>
    </Grid.Col>
    {openSelectedSessionDrawer ? <Grid.Col span={4}>

      <Group justify='space-between'>
        <Title order={2}>{selectedSession?.roomNames}</Title>
        <CloseButton onClick={() => {setOpenSelectedSessionDrawer(false)}}/>
      </Group>
      <Stack justify="space-between" mt="md">
            <Text>Starts: {startTimeString}</Text>
            <Text>Ends: {endTimeString}</Text>
            <Text>Bookings: {1}/{selectedSession?.totalSlots}</Text>
      </Stack>
      
      <Divider variant='dotted' className='my-6'/>
      
      <Box className='py-4'>
      <Accordion variant="contained" defaultValue="Apples">
      {sessions.map((item) => (
      <Accordion.Item key={item.id} value={item.id}>
        <Accordion.Control >{item.start} : {item.end}</Accordion.Control>
        <Accordion.Panel>
          {/* {item.description} */}
          <Group justify='space-between'>
          <Stack>
              {item.bookings !==undefined && item.bookings.length > 0 ? <>
                <Group gap={4}>
                  <Anchor component='div'  underline="hover"  onClick={() => setOpenBookingsDrawer(true)} className='mr-4'>
                    {item.bookings[0].firstname} {item.bookings[0].lastname}
                  </Anchor>
                  <Text className='font-bold'>{item.bookings[0].neph}</Text>
                  <CopyButton value={item.bookings[0].neph} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                          {copied ? (
                            <FontAwesomeIcon icon={duotone("check-circle")} color='green'></FontAwesomeIcon>
                          ) : (
                            <FontAwesomeIcon icon={duotone("copy")} color='gray'></FontAwesomeIcon>
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                  <Text>{item.bookings[0].code}</Text>
                  <CopyButton value={item.bookings[0].code} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                          {copied ? (
                            <FontAwesomeIcon icon={duotone("check-circle")} color='green'></FontAwesomeIcon>
                          ) : (
                            <FontAwesomeIcon icon={duotone("copy")} color='gray'></FontAwesomeIcon>
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Group>
                </> : <></>}
          </Stack>
          {isPastTime ? <></> : <>
          <Menu > 
                <Menu.Target >
                    <Button >
                        <FontAwesomeIcon icon={duotone("user-plus")} color='white'></FontAwesomeIcon>
                    </Button>
                </Menu.Target>
                <Menu.Dropdown className='p-2 shadow-lg'>
                        <MenuLabel>{lang.Text('Add booking')}</MenuLabel>
                        <Menu.Item onClick={() => setOpenAddCodeDeLaRoute(true)} key={'booking.id'} 
                            leftSection={<FontAwesomeIcon icon={ duotone("motorcycle") } size='2xl' color='gray' />}>
                            Code de la Route: MOTO
                        </Menu.Item>
                        <Menu.Item onClick={() => setOpenAddCodeDeLaRoute(true)} key={'booking.id'} 
                            leftSection={<FontAwesomeIcon icon={ duotone("car") } size='2xl' color='gray' />}>
                            Code de la Route: Voiture
                        </Menu.Item>
                </Menu.Dropdown>            
            </Menu>
            </>}
            </Group>
        </Accordion.Panel>
      </Accordion.Item>
      ))}
      </Accordion>
      <Group className='mt-6' justify='end'>
                

      
        </Group>

        </Box>

    </Grid.Col>: <></>}
    </Grid>

     {/* <Drawer opened={openSelectedSessionDrawer}
     position='right'
     size={isMobile? '100%' : isLargeScreen ? '600px' : '600px'}
     padding="sm"
     transitionProps={{transition: 'slide-left'}}
     onClose={() => {setOpenSelectedSessionDrawer(false)}}
     withCloseButton={true}
     >
          <Title>{selectedSession?.roomNames}</Title>
          
          <Group justify="space-between" mt="md">
        <Text >
            Public booking available
          </Text><Badge variant="filled" color='green'>{lang.Text("Scheduled")}</Badge>
          </Group>

          <Group justify="space-between" mt="md">
            <Text>Starts: {"07:00"}</Text>
            <Text>Ends: {"09:00"}</Text>
          </Group>

        <Table horizontalSpacing={"md"} verticalSpacing={"xs"} layout="fixed">
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Th colSpan={2}>
                      {lang.Text("Name")}
                    </Table.Th>
                    <Table.Th colSpan={3}>
                      {lang.Text("Test")}
                    </Table.Th>
                    <Table.Th colSpan={1}>
                      {lang.Text("Duration")}
                    </Table.Th>
                    <Table.Th colSpan={1}>
                      
                    </Table.Th>
                  </Table.Tr>
                </Table.Tbody>
                <Table.Tbody>
                  {selectedSession?.bookings.map(booking => {
                    return <Table.Tr>
                      <Table.Td colSpan={2}>
                        {booking.firstName} {booking.lastName}
                      </Table.Td>
                      <Table.Td colSpan={3}>
                        {booking.testCategoryName}
                      </Table.Td>
                      <Table.Td colSpan={1}>
                        {booking.durationInMinutes} {lang.Text("Min").toLowerCase()}
                      </Table.Td>
                      <Table.Td colSpan={1}>
                        <Button onClick={() => { alert(booking.purchaseId)}} color='blue'>{lang.Text("View")}</Button>
                      </Table.Td>
                    </Table.Tr>
                  })}
                </Table.Tbody>
        </Table>
     </Drawer> */}

     <Drawer opened={openAddCodeDeLaRoute}
            position="right"
            size={isMobile? '100%' : isLargeScreen ? '80%' : '60%'}
            padding={isMobile ? 'sm' : 'sm'}
            transitionProps={{ transition: 'slide-left' }}
            onClose={() => {setOpenAddCodeDeLaRoute(false)}}
            withCloseButton= {true}
            title={ <Box className='ml-8'>
            <Group>
                <Image w={350}
                  src="https://images.ctfassets.net/2b5s0apidvye/66b5HHtUzmv2dZLrF1w966/2cdf77d2431b706046c9b86617b59c7e/fc-big-logo-blue.png?h=250" />
              <div className='p-6'>
              <Title order={4} >{lang.Text('Add booking')}</Title>
              <Text >{DateTime.utc().toLocaleString()}</Text>
              <Text className='font-bold' >Code de la Route: Voiture</Text>
              </div>
            </Group>
            </Box>}
            zIndex={1200}
        >
        <Divider variant='dotted' />
        
        <SimpleGrid cols={2} >
            <Box  className='p-6'>
                
                <Title order={4} className='mb-4'>{lang.Text('Personal Information')}</Title>
                
                <TextInput label="First Name" placeholder=" First Name" withAsterisk {...form.getInputProps('firstname')} />
                <TextInput label="Last Name" mt="md" placeholder="Last Name" withAsterisk   {...form.getInputProps('lastname')} />
                <TextInput
                  label="Birtdate"
                  placeholder="Birtdate"
                  withAsterisk
                  mt="md"
                  {...form.getInputProps('job')}
                />
                <TextInput
                  label="NEPH"
                  placeholder="NEPH"
                  withAsterisk
                  mt="md"
                  {...form.getInputProps('job')}
                />
                <TextInput
                  label="Email"
                  placeholder="Email"
                  withAsterisk
                  mt="md"
                  {...form.getInputProps('email')}
                />
                <TextInput
                  label="Phone number"
                  placeholder="Phone number"
                  withAsterisk
                  mt="md"
                  {...form.getInputProps('favoriteColor')}
                />
                {checkEligble ?
                <Center className='my-8'>
                  <Loader variant=""/>
                </Center>
                : <></>}
          </Box>

          <Box  className='p-6'>
            <Title order={4} className='mb-4'>{lang.Text('Booking settings')}</Title>
            <Divider variant='dotted' className='my-6'/>
                <Switch
                    label="SNU"
                    onClick={() => {setCheckSnu(true)}}
                  />
                  {checkSnu ? 
                  <Center className='my-8'>
                   <Loader variant=""/>
                  </Center> 
                  :<></>}
                
            <Divider variant='dotted' className='my-6'/>
          </Box>
        </SimpleGrid>

        <Divider variant='dotted' className='my-6'/>

        <Group className='p-8'>
            <Button size='lg'>Add</Button>
        </Group>
      
      </Drawer>

      <Drawer opened={openBookingsDrawer}
            position="right"
            size={isMobile? '100%' : isLargeScreen ? '50%' : '40%'}
            padding={isMobile ? 'sm' : 'sm'}
            transitionProps={{ transition: 'slide-left' }}
            onClose={() => {setOpenBookingsDrawer(false)}}
            withCloseButton= {true}
            title={ <Box className='ml-8'>
            <Group>
                <Image w={350}
                  src="https://images.ctfassets.net/2b5s0apidvye/66b5HHtUzmv2dZLrF1w966/2cdf77d2431b706046c9b86617b59c7e/fc-big-logo-blue.png?h=250" />
              <div className='p-6'>
              <Title order={4} >{lang.Text('Booking')}</Title>
              <Text >2023-12-11 07:30</Text>
              <Text className='font-bold' >Code de la Route: Voiture</Text>
              </div>
            </Group>
            </Box>}
            zIndex={1200}
        >
        <Divider variant='dotted' />
        
        <Center className='my-8'>
          <Loader variant=""/>
        </Center>
    </Drawer>
      </>
    )  
}