import { Badge, Button, Card, Divider, Group, Timeline, Text, Stack, Slider, Center } from "@mantine/core";
import { useLanguage } from "../../../common/language/Language";
import { GlobalSecurity } from "../../../common/security/GlobalSecurity";
import { ProctorSession } from "../api/ProctorApi";
import { regular, light, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { DateTime } from "luxon";


export const ResultWidget = (props: {session: ProctorSession}) => {
    const lang = useLanguage();
    const {accessToken} = GlobalSecurity();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');    
    
    //console.info("ResultWidget ->", props.session);

    const getMaxCount = () => {
        return props.session.result.answerCount + props.session.result.unAnswerCount;
    }

    return (<>
    {props.session.result !==null && props.session.isFinished ? 
    <Card className="min-h-[200px]" withBorder>
        <Group justify="space-between" >
            <Text className="font-bold">{lang.Text('Grade')}</Text>
        </Group>
        <Divider variant="dashed" className="my-2" />
        <Group className="min-h-[130px]" grow>
            <Stack >
            <Text className="text-sm">{lang.Text('Date: ')}<Text span fw={700} inherit>
                {DateTime.fromISO(props.session.result.date).toLocaleString(DateTime.DATETIME_SHORT)}
                    </Text></Text>
            <Text className="text-sm">{lang.Text('Score: ')}<Text span fw={700} inherit>{props.session.result.score}</Text></Text>
            <Text className="text-sm">{lang.Text('Passed: ')}<Text span fw={700} inherit>
                {props.session.result.passed ? lang.Text('Yes') : lang.Text('No')}
                </Text></Text>
            </Stack>
            <div>
                <Slider 
                    marks={[
                        { value: 0, label: '0'},
                        { value: getMaxCount()/2, label: getMaxCount()/2 }, 
                        { value: getMaxCount(), label: getMaxCount() }]}
                    value={props.session.result.score}
                    label={'Score: ' + props.session.result.score}
                    labelAlwaysOn 
                    max={getMaxCount()}
                /> 
           </div>

        </Group>
        <Group justify="end">
            <Button variant="subtle" color="primary" size="sm" >
                <FontAwesomeIcon  icon={light('shield-halved')} size="lg"/>
            </Button>
            <Button variant="subtle" color="primary" size="sm" >
                <FontAwesomeIcon  icon={light('envelope-circle-check')} size="lg"/>
            </Button>
        </Group>
    </Card>: <></>}
    </>);
}
