import { useNavigate } from "react-router-dom";
import { Module } from "../../common/api/Module";
import { useLanguage } from "../../common/language/Language";
import { regular, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Stack, Loader, Divider, Button, Text, Paper, Title, Group, Badge, useMantineTheme, SegmentedControl, Autocomplete, Avatar, 
  Accordion, CopyButton, Drawer, ActionIcon, TextInput,  Container, Center, Tabs, Card, SimpleGrid, Box, useCombobox, Combobox, InputBase, Input, NumberInput, Table,} from "@mantine/core";

import { useOs, useMediaQuery, useDisclosure, useForceUpdate, useLocalStorage } from "@mantine/hooks";
import { forwardRef, useEffect, useState } from "react";
import { GlobalSecurity } from "../../common/security/GlobalSecurity";
import { DataPoint, PaymentData } from "./api/PaymentApi";
import { useContent } from "../../common/content/useContent";
import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { usePubsub } from "../../common/pubsub/usePubsub";
import { AlertModule } from "../../common/components/dashboard/Alert";
import { AuthenticatedContent } from "../../common/security/components/AuthenticatedContent";
import { Footer } from "../../common/content/Footer";


import {
  ConnectPayments,
  ConnectPayouts,
  ConnectPaymentDetails
} from "@stripe/react-connect-js";

import { ErrorBoundary } from "react-error-boundary";

import { Http, fetcher } from "../../common/Fetcher";
import { GlobalAppsettings } from "../../GlobalSettings";

import { AreaChart, BadgeDelta, Flex, Metric } from "@tremor/react";
import useSWR from "swr";

import { ModuleTopBar } from "../../common/components/dashboard/DashboardGrid";
import { StripeConnectContext } from "./components/stripeConnect";
import { useForm } from "@mantine/form";
import { DateInput, DatePickerInput } from "@mantine/dates";
import moment from "moment";
import luxon from "luxon";

const chartdata = [
    {
      date: "Jan 23",
      Revenue: 2890,
      Payouts: 2338,
    },
    {
      date: "Feb 23",
      Revenue: 2756,
      Payouts: 2103,
    },
    {
      date: "Mar 23",
      Revenue: 3322,
      Payouts: 2194,
    },
    {
      date: "Apr 23",
      Revenue: 3470,
      Payouts: 2108,
    },
    {
      date: "May 23",
      Revenue: 3475,
      Payouts: 1812,
    },
    {
      date: "Jun 23",
      Revenue: 3129,
      Payouts: 1726,
    },
  ];
  
  const dataFormatter = (number: number) => {
    return "€ " + Intl.NumberFormat("fr").format(number).toString();
  };


export const PaymentModule = (props: {module?: Module}) => {
    const lang = useLanguage();
    const navigate = useNavigate();
    const {accessToken} = GlobalSecurity();
    const [isLoading, setIsLoading] = useState(true);
    
    const {data,  mutate} = useSWR<PaymentData>(
            accessToken!==undefined ? ["/api/payments/GetPaymentData", accessToken] : null,
            () => fetcher("/api/payments/GetPaymentData", Http.GET, accessToken), {
                keepPreviousData: true,
                refreshInterval: 0,
                revalidateOnFocus: true,
                onError: (err) => {
                    console.error(err);    
                },
            },            
    );

    const [historyData, setHistoryData] = useState<any>([]);

    useEffect(() => {
        if (data?.success){
            console.log("Paymentdata:",data);
            setHistoryData(data.result.history);
        }
        else
        {
            data?.errorMessage && console.error(data.errorMessage);
        }
        setIsLoading(false);
    }, [data]);

    const refresh = () => {
        setIsLoading(true);        
        mutate(data).then(() => {
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        });
    }

    return (<div className="p-4">
    <ModuleTopBar
            title={lang.Text('Payments')}
            icon={"S"}
            iconColor={"violet"}
            badge={<></>}
        >
        {/* {data?.result !== undefined && data?.result.accountId ?
        <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("link")}/>}
            rightSection={<FontAwesomeIcon  icon={duotone("arrow-up-right-from-square")}/>}
            onClick={() => window.open("https://dashboard.stripe.com/" + data.result.accountId + "/dashboard","_new")}
            >{lang.Text('Stripe Dashboard')}</Menu.Item>: <></>} */}

        <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("rotate-right")}/>}
                onClick={() => refresh()}>{lang.Text('Refresh')}</Menu.Item> 
    </ModuleTopBar>
    <Stack className='mt-4' align="stretch" justify="space-between">    
        {isLoading ? <Center><Loader variant="dots" /></Center> : <>
        
        <AreaChart
        className="h-64"
        data={historyData}
        index="date"
        categories={["revenue", "payouts"]}
        //   showLegend={false}
        colors={["indigo", "cyan"]}
        valueFormatter={dataFormatter}
        showXAxis={true}
        showGridLines={false}
        startEndOnly={true}
        showYAxis={false}
        />
        
        </>}
      <div>
        <Divider variant="dashed" className="w-full my-1 py-1" />
        <Group justify="end">
            <Button variant="subtle" className="text-base" rightSection={<FontAwesomeIcon  icon={duotone("arrow-right")}/>}
                        onClick={() => navigate('/payments')}
                        >{lang.Text('Open payments')}
            </Button>
        </Group>
      </div>
    </Stack>

    </div>)
}

export interface PaymentItem {
  organisation: string;
  amount: number;
  date: Date;
  email: string;
  status: string;
}

export const PaymentsPage = (props: {module?: Module}) => {
    const lang = useLanguage();
    const theme = useMantineTheme();
    const {isLoggedIn, organisation, userProfile} = GlobalSecurity();
    const app = usePubsub();
    const navigate = useNavigate();

    const [detailsVisible, setDetailsVisible] = useState(false);

    const [viewValue, setViewValue] = useState<string>('overview');

    const [currentPaymentIntent, setCurrentPaymentIntent] = useState<string>('');

    const [addPaymentPanel, { open: openAddPanel, close: closeAddPanel }] = useDisclosure(false);

    const [payments, setPayments] = useLocalStorage<PaymentItem[]>( { key: 'yc-payments', defaultValue: []});

    const {accessToken} = GlobalSecurity();
    const [isLoading, setIsLoading] = useState(true);

    const [payout, showPayout] = useState(false);


    const {data,  mutate} = useSWR<PaymentData>(
            accessToken!==undefined ? ["/api/payments/GetPaymentData", accessToken] : null,
            () => fetcher("/api/payments/GetPaymentData", Http.GET, accessToken), {
                keepPreviousData: true,
                refreshInterval: 0,
                revalidateOnFocus: true,
                onError: (err) => {
                    console.error(err);    
                },
            },            
    );

    const [historyData, setHistoryData] = useState<DataPoint[]>([]);
    const [forecastData, setForecastData] = useState<DataPoint[]>([]);

    useEffect(() => {
        if (data?.success){
            console.log("Paymentdata:",data);
            setHistoryData(data.result.history);
            setForecastData(data.result.forecast);
        }
        else
        {
            data?.errorMessage && console.error(data.errorMessage);
        }
        setIsLoading(false);
    }, [data]);

    const refresh = () => {
        setIsLoading(true);        
        mutate(data).then(() => {
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        });
    }

    const form = useForm({
      initialValues: {
        organisation: '',
        amount: 0.00,
        date: new Date(),
        email: '',
        status: 'initial'        
      },
    });

    const orgs = ["Creil","Sartrouville","Marseille 14","Saint Denis"];

    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const organisations = orgs.map((item) => (
      <Combobox.Option value={item} key={item}>
        {item}
      </Combobox.Option>
    ));


    const getDate = (date: Date): String => {
      try {
        let dateStr = date.toLocaleDateString();
        return dateStr;  
      } catch (error) {
        return '';
      }
    }

    const rows = payments?.map((element) => (
      <Table.Tr key={element.organisation}>
         <Table.Td>{element.organisation}</Table.Td>
        <Table.Td>€ {element.amount}</Table.Td>
        <Table.Td>{getDate(element.date).trim()}</Table.Td>
        <Table.Td>{element.email}</Table.Td>
        {/* <Table.Td>{element.status}</Table.Td> */}
        <Table.Td><ActionIcon variant="subtle" color={'red'}><FontAwesomeIcon  icon={duotone("trash")}/></ActionIcon></Table.Td>
      </Table.Tr>
    ));

    const [paymentLoading, setPaymentLoading] = useState(false);

    return (<>
    <AuthenticatedContent path="/payments">
    <StripeConnectContext>
          
     <Paper className="pt-20 p-10 lg:mx-16 md:mx-2 min-h-screen" shadow="md" radius="sm" withBorder>
     <Group justify="start" className="ml-[-30px]">
            <Button variant="subtle"  className="text-sm" leftSection={<FontAwesomeIcon icon={duotone('backward')}/>}
                onClick={() => navigate('/dashboard')}>
                {lang.Text('Back to dashboard')}
            </Button>
        </Group>
        <Group className="mt-4 w-full"  justify="space-between">
            <Title order={2}>{lang.Text('Payments')}</Title>
            <AlertModule id="payments.alert"/>
            <Badge>{organisation?.name}</Badge>
        </Group>

        <Divider variant="dashed" className="my-4" />
        {/* Action bar */}
        <Group className="mt-4" justify="space-between" >
             <SegmentedControl
                color={'gray'}
                size="md"
                value={viewValue}
                onChange={setViewValue}
                data={[
                    { label: lang.Text('Overview'), value: 'overview' },
                    { label: lang.Text('Revenue'), value: 'revenue' },
                    { label: lang.Text('Balance'), value: 'balance' },
                    { label: lang.Text('Payments'), value: 'payments' },
                    { label: lang.Text('Settings'), value: 'settings' },
                ]}
                />
              <Group justify="space-between">
                <Button variant="filled" color="blue" onClick={() => showPayout(true)} leftSection={<FontAwesomeIcon  icon={regular("bank")} />}>{lang.Text('Payout')}</Button>
                <div>
                <Button variant="subtle" color="gray" onClick={refresh} leftSection={<FontAwesomeIcon  icon={regular("rotate-right")} />}>{lang.Text('Refresh')}</Button>
                <Button variant="subtle" color="gray"  rightSection={<FontAwesomeIcon  icon={duotone("arrow-up-right-from-square")}/>}
                      onClick={() => window.open("https://dashboard.stripe.com/" + data?.result.accountId + "/dashboard","_new")}
                    >{lang.Text('Stripe Dashboard')}</Button>
                 </div>
              </Group>
        </Group>
        <Divider variant="dashed" className="my-4" />
        
        <Tabs color="teal" value={viewValue}>
        <Tabs.Panel value="overview" >
            {isLoading ? <Center><Loader variant="dots" /></Center> : <>
        
        <Group grow>
            <div>
                <Title order={4}>{lang.Text('Revenue and Payouts')}</Title>
                <AreaChart
                    key={"chart1"}
                    className="h-96 w-full"
                    data={historyData}
                    index="date"
                    categories={["revenue", "payouts"]}
                    showLegend={true}
                    colors={["indigo", "cyan"]}
                    valueFormatter={dataFormatter}
                    showXAxis={true}
                    showGridLines={true}
                    startEndOnly={true}
                    
                        //showYAxis={false}
                />
            </div>
            <div>
                <Title order={4}>{lang.Text('Forecast')}</Title>
                <AreaChart
                    key={"chart2"}
                    className="h-96 w-full"
                    data={forecastData}
                    index="date"
                    categories={["revenue", "payouts"]}
                    //   showLegend={false}
                    colors={["stone", "slate"]}
                    valueFormatter={dataFormatter}
                    showXAxis={true}
                    showGridLines={true}
                    startEndOnly={true}
                    showYAxis={false}
                />
            </div>
        </Group>

        <Divider variant="dashed" className="w-full my-6" />
        <Group grow>
        <Card key={'item.title'} withBorder={true} className="">
          <Text>{lang.Text('Total Gross Revenue')}</Text>
          <Flex justifyContent="start" alignItems="baseline" className="truncate space-x-3">
            <Title order={2} className="text-gray-800">{data?.result && dataFormatter(data?.result.totalNetVolume)}</Title>
          </Flex>
          <Flex justifyContent="start" className="space-x-2 mt-4">
            <BadgeDelta deltaType={"increase"} />
            <Flex justifyContent="start" className="space-x-1 truncate">
              <Text c={"green"}>{564}</Text>
              <Text className="truncate">{lang.Text('to previous month')}</Text>
            </Flex>
          </Flex>
        </Card>
        {/* <Card key={'item.title'} withBorder={true} className="bg-emerald-50">
          <Text>{lang.Text('Total Payouts')}</Text>
          <Flex justifyContent="start" alignItems="baseline" className="truncate space-x-3">
            <Title order={2} className="text-gray-800">{data?.result && dataFormatter(data?.result.totalPayouts)}</Title>
          </Flex>
          <Flex justifyContent="start" className="space-x-2 mt-4">
            <BadgeDelta deltaType={"moderateIncrease"} />
            <Flex justifyContent="start" className="space-x-1 truncate">
              <Text color={"green"}>{564}</Text>
              <Text className="truncate">{lang.Text('to previous month')}</Text>
            </Flex>
          </Flex>
        </Card>
        <Card key={'item.title'} withBorder={true} className="bg-neutral-50">
          <Text>{lang.Text('Upcoming Payouts')}</Text>
          <Flex justifyContent="start" alignItems="baseline" className="truncate space-x-3">
            <Title order={2} className="text-gray-800">{data?.result && dataFormatter(data?.result.upcomingPayouts)}</Title>
          </Flex>
          <Flex justifyContent="start" className="space-x-2 mt-4">
            <BadgeDelta deltaType={"moderateIncrease"} />
            <Flex justifyContent="start" className="space-x-1 truncate">
              <Text color={"green"}>{564}</Text>
              <Text className="truncate">{lang.Text('to previous month')}</Text>
            </Flex>
          </Flex>
        </Card> */}

        </Group>

        </>}
        </Tabs.Panel>
          
        <Tabs.Panel value="revenue" >
            <ErrorBoundary fallback={<>
              {lang.Text('Something went wrong')}
              <Button variant="outline" className="ml-4" leftSection={<FontAwesomeIcon  icon={duotone("redo-alt")} size="xl"/>}
                onClick={() => window.location.reload()}>{lang.Text('Try again')}</Button>
            </>}>
            <Title order={4}>{lang.Text('Latest')}</Title>
            <ConnectPayments />
            </ErrorBoundary>
        </Tabs.Panel>

        <Tabs.Panel value="payments" >
            <ErrorBoundary fallback={<>
              {lang.Text('Something went wrong')}
              <Button variant="outline" className="ml-4" leftSection={<FontAwesomeIcon  icon={duotone("redo-alt")} size="xl"/>}
                onClick={() => window.location.reload()}>{lang.Text('Try again')}</Button>
            </>}>
            <Title order={4} className="pb-6">{lang.Text('Payments')}</Title>
            <Group>
              <Button 
                leftSection={<FontAwesomeIcon  icon={duotone("plus")} size="xl"/>}
                variant="filled" onClick={openAddPanel}>{lang.Text('Add Payment')}
              </Button>

              <Button 
                leftSection={<FontAwesomeIcon  icon={duotone("plus")} size="xl"/>}
                color="green" variant="filled" onClick={openAddPanel}>{lang.Text('Calculate')}
              </Button>
            </Group>

            <SimpleGrid cols={{ base: 1, sm: 1, md: 1, lg: 2, xl: 3  }}>
            <Box maw={640} className="my-8">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{lang.Text('Organisation')}</Table.Th>
                  <Table.Th>{lang.Text('Amount')}</Table.Th>
                  <Table.Th>{lang.Text('Payment Date')}</Table.Th>
                  {/* <Table.Th>{lang.Text('Email')}</Table.Th> */}
                  {/* <Table.Th>Status</Table.Th> */}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>

            <Divider variant="dashed" className="my-4" />
            <Group justify="end">
              <Button variant="subtle" onClick={() => {
                setPaymentLoading(true);
              }
              } loading={paymentLoading}>{lang.Text('Send all payments')}</Button>
            </Group>
            </Box>

            <Box>
              <Title order={4} className="pb-6">{lang.Text('Ongoing payments')}</Title>
              <Divider variant="dashed" className="my-4" /> 

            </Box>

            <Box>
            <Title order={4} className="pb-6">{lang.Text('Payment History')}</Title>
            <Divider variant="dashed" className="my-4" />

            </Box>

            </SimpleGrid>

            
            </ErrorBoundary>
        </Tabs.Panel>

        <Tabs.Panel value="balance" >
            <ErrorBoundary fallback={<>
              {lang.Text('Something went wrong')}
              <Button variant="outline" className="ml-4" leftSection={<FontAwesomeIcon  icon={duotone("redo-alt")} size="xl"/>}
                onClick={() => window.location.reload()}>{lang.Text('Try again')}</Button>
              </>}>
            <Title order={4} className="my-4">{lang.Text('Balance')}</Title>
            <ConnectPayouts />
            </ErrorBoundary>
        </Tabs.Panel>

        <Tabs.Panel value="settings" >
          <Title order={4}>{lang.Text('Payout schedule')}</Title>
          <Container className="h-60"></Container>
          <Divider variant="dashed" className="my-4" />
          <Title order={4}>{lang.Text('Bank accounts')}</Title>
          <Container className="h-60"></Container>
          <Divider variant="dashed" className="my-4" />
          <Title order={4}>{lang.Text('Reports')}</Title>
          <Container className="h-60"></Container>
          <Divider variant="dashed" className="my-4" />

        </Tabs.Panel>
      </Tabs>
            
      {/* <ConnectPaymentDetails chargeId={currentPaymentIntent} onClose={onDetailsClose} visible={detailsVisible} />    */}
            
    </Paper> 
    
    
     {/* Add payment panel */}
     <Drawer opened={addPaymentPanel} position="right"
             title={<Title order={3} className="mt-2">{lang.Text('Add Payment')}</Title>}
            onClose={closeAddPanel} withCloseButton size="35%">
          
          <Divider variant="dashed" className="my-4" />

          <Box className="p-8" mx="auto">
      
          <Combobox
             
              store={combobox}
              onOptionSubmit={(val) => {
                form.setFieldValue('organisation', val);
                combobox.closeDropdown();
              }}
            >
              <Combobox.Target>
              <TextInput label="Organisation" placeholder="organisation" {...form.getInputProps('organisation')} 
                  rightSection={<Combobox.Chevron />}
                  onClick={() => combobox.toggleDropdown()}
                  />
                
                {/* <InputBase {...form.getInputProps('organisation')}
                  component="button"
                  pointer
                  rightSection={<Combobox.Chevron />}
                  onClick={() => combobox.toggleDropdown()}
                >
                </InputBase> */}
              </Combobox.Target>

              <Combobox.Dropdown>
                <Combobox.Options>{organisations}</Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
          
            <NumberInput
              label="Amount (in cents)"
              description=""
              {...form.getInputProps('amount')}
              className="my-4"
              prefix="€ "
              defaultValue={100.00}
              step={1000}
            />

          {/*  */}

          {/* <TextInput  /> */}

          <DatePickerInput label="Payment Date"  
            minDate={new Date()}
            maxDate={moment(new Date()).add(1, 'month').toDate()}
            size="md"
            {...form.getInputProps('date')} className="my-4"/>

          <TextInput label="Email" {...form.getInputProps('email')} className="my-4" />

          <Group justify="end">
            <Button onClick={() => {
                if (payments!==undefined)
                setPayments([...payments, {
                  organisation: form.values.organisation,
                  amount: form.values.amount,
                  date: form.values.date,
                  email: form.values.email,
                  status: form.values.status,
                }]);

                form.reset();

                closeAddPanel();

            }} >{lang.Text('Add')}</Button>
          </Group>
                
        </Box>
        <Divider variant="dashed" className="my-4" />
        

      </Drawer>


     {/* Payoit */}
     <Drawer opened={payout} position="right"
             title={<Title order={3} className="mt-2">{lang.Text('Payout')}</Title>}
            onClose={() => showPayout(false)} withCloseButton size="55%">
          
          <Divider variant="dashed" className="my-4" />
          <Box className="p-26" mx="auto">

              <Center ><Loader /></Center>


          </Box>
    </Drawer>
    
    </StripeConnectContext>
    </AuthenticatedContent>
    <Footer/>
    </>)

}