import { Http, fetcher } from "../../../common/Fetcher";
import { GlobalAppsettings } from "../../../GlobalSettings";
import { ResponseBase } from "../../../common/api/common";
import { Language } from "../../../common/language/Language";

export type ProctorDataBase<T = {}> = {
    data: T;
} & ResponseBase;


export interface ProctorData {
    success: boolean;
    errorMessage: string;
    result: ProctorModuleInfo;
}

export interface ProctorModuleInfo {
    numOfSessions: number;
    numOfOnboarding: number;
    numOfError: number;
    numOfFlagged: number;
}

export interface ProctorSession {
    
    id:           string;
    orgId:          string;
    examId:       string;
    examTitle:    string;
    code:         string;
    examUrl:      string;
    examLogoUrl:  string;
    isOnboarding: boolean;
    isRunning:    boolean;
    isFinished:   boolean;
    updated:      string;
    result:       ResultResult;
    examDuration: string;

    onboardingId:   string;
    monitoringId:   string;
    bookingId:      string;
    
    state:        string;
    group:        string;
    value:        string;
    type:        string;
    status: string;
    image: string;
    description: string;
    testcenter: string;
    
    errorMessage: null;
}

export interface Monitoring {
    area:                        string;
    sessionId:                   string;
    events:                      Event[];
    isValid:                     boolean;
    errorMessage:                string;
    createdDate:                 string;
    updateDate:                  string;
    isStarted:                   boolean;
    isFlagged:                   boolean;
    flaggedReason:               string;
    flaggedInternalReason:       string;
    isFinished:                  boolean;
    startDate:                   string;
    endDate:                     string;
    isRemoteProctoring:          boolean;
    proctoringToken:             string;
    proctoringCandidateToken:    string;
    proctoringPasscode:          string;
    proctoringCandidatePasscode: string;
    id:                          string;
    orgId:                       string;
    objectId:                    string;
    isDeleted:                   boolean;
    summary:                     string;
    aiProctor:                   AiProctor[];
}

export interface AiProctor {
    summary:         string;
    timeStamp:      string;
}

export const ProctorApi = {
    getStatusColorValue : (status: string) => {
        switch (status) {
            case 'in progress': return 'green';
            case 'flagged': return 'yellow.7';
            case 'error': return 'red';
            case 'finished': return 'gray.6';
            case 'onboarding': return 'violet.5';
            default: return 'gray.6';
        }
    },
    fromSession: (session: ProctorSession) => {
        session.group = session.state;
        session.status = session.state;
        session.value = session.id;
        session.description = session.examTitle;
    },
    getSessionitem: (session: ProctorSession) => {
        return {
            //id, email, description, value, image, testcenter, status,
            id: session.id,
            title: session.examTitle ? session.examTitle : 'no title',
            description: session.examTitle ? session.examTitle : 'no description',
            image: session.examLogoUrl,
            status: session.state,
            group: session.state,
            value: session.id,
            testcenter: session.testcenter ? session.testcenter : 'no testcenter',
            code: session.code ? session.code : 'no code',
        }
    },
    Monitoring: {
    getStatusColor: (item?: Monitoring) => {
        if (item?.isFinished) return 'green';
        if (item?.isStarted) return 'gray.6';
        if (item?.isFlagged) return 'yellow.7';
        if (item?.errorMessage) return 'red';
        return 'gray.6';
    }
    ,
    getStatusText: (item?: Monitoring, lang?: Language) => {
        if (item?.isFinished) return lang?.Text('Finished');
        if (item?.isStarted) return lang?.Text('In progress');
        if (item?.isFlagged) return lang?.Text('Flagged');
        if (item?.errorMessage) return lang?.Text('Error');
        return lang?.Text('Unknown');
    }},

    getSummary(id?: string, token?: string) {
        return fetcher('/api/proctor/getsummary?id=' + id, Http.GET, token);
    }
}

export interface Event {
    id:            string;
    eventTypeCode: number;
    eventType:     string;
    eventText:     string;
    eventTime:     string;
    data:          string;
}

export interface Onboarding {
    status:             number;
    timeStampUtc:       string;
    bookingId:          string;
    monitoringId:       null;
    isValid:            boolean;
    isCompleted:        boolean;
    reason:             null;
    testCategory:       TestCategory;
    booking:            Booking;
    sessionId:          string;
    errorMessage:       null;
    createdDate:        string;
    updateDate:         string;
 
    proctoringToken:    null;
    proctoringPasscode: null;
    steps:              Step[];
    isRemoteProctored:  boolean;
    id:                 string;
    objectId:           string;
    isDeleted:          boolean;
}

export interface Booking {
    id:                           string;
    email:                        string;
    name:                         string;
    firstName:                    string;
    lastName:                     string;
    language:                     null;
    country:                      null;
    phone:                        null;
    identity:                     null;
    identityProvider:             number;
    productId:                    string;
    productRef:                   ProductRef;
    onboardingProvider:           string;
    onboardingStatus:             string;
    isStarted:                    boolean;
    started:                      null;
    isCancelled:                  boolean;
    cancelledDateUtc:             null;
    completedDate:                string;
    isError:                      boolean;
    errorMessage:                 null;
    bookingConfirmationSent:      boolean;
    bookingConfirmationSentDate:  null;
    cancellationEmailSent:        boolean;
    cancellationEmailSentDateUtc: null;
    examInfo:                     ExamInfo;
    code:                         string;
    onboardingLink:               string;
    onboardingIsCompleted:        boolean;
    examLink:                     string;
    results:                      ResultElement[];
    sessionCode:                  string;
    hasTraining:                  boolean;
}

export interface ExamInfo {
    testcenterId:          string;
    examId:                string;
    examDate:              null;
    testExecutionLocation: number;
}

export interface ProductRef {
    title:            string;
    price:            number;
    vat:              number;
    currency:         string;
    onSale:           null;
    packageItems:     any[];
    examId:           string;
    productId:        string;
    courseId:         null;
    count:            number;
    integrationInfos: any[];
    imageUrl:         string;
}

export interface ResultElement {
    date:            string;
    answerCount:     number;
    unAnswerCount:   number;
    correctCount:    number;
    passed:          boolean;
    isCertification: boolean;
    isAssessment:    boolean;
    assesmentLevel:  number;
    score:           number;
    examId:          string;
    examName:        null;
    errorMessage:    null;
    isError:         boolean;
    sessionId:       string;
}

export interface Step {
    id:              string;
    description:     string;
    isCompleted:     boolean;
    contentRef:      string;
    stepType:        number;
    isValid:         boolean;
    autoNextOnValid: boolean;
    reason:          string;
    errorMessage:    null | string;
    order:           number;
    settings:        any[];
    data:            StepData | null;
    link:            null | string;
    isProcessing:    boolean;
    requireProctorAction: boolean;
}

export interface StepData {
    Accepted?:       any[];
    address?:        Array<Array<any[]>>;
    dob?:            any[];
    first_name?:     any[];
    id_number?:      any[];
    id_number_type?: any[];
    last_name?:      any[];
}

export interface TestCategory {
    orgParent:                          string;
    acronym:                            string;
    name:                               string;
    description:                        string;
    available:                          boolean;
    testCoreId:                         string;
    testCoreOrgId:                      null;
    durationInMinutes:                  number;
    imageUri:                           string;
    programId:                          string;
    canBeTakenOnline:                   boolean;
    canBeTakenOnPremise:                boolean;
    bookingConfirmationEmailTemplateId: string;
    bookingCancellationEmailTemplateId: string;
    bookingMovedEmailTemplateId:        string;
    testResultEmailTemplateId:          string;
    testUrl:                            null;
    contentRef:                         null;
    onboardingSteps:                    OnboardingStep[];
    id:                                 string;
    orgId:                              string;
    objectId:                           string;
    isDeleted:                          boolean;
}

export interface OnboardingStep {
    id:              string;
    description:     string;
    isCompleted:     boolean;
    contentRef:      string;
    stepType:        number;
    isValid:         boolean;
    autoNextOnValid: boolean;
    reason:          null | string;
    errorMessage:    null | string;
    order:           number;
    settings:        any[];
    data:            any;
    link:            null | string;
    isProcessing:    boolean;
}

export interface ResultResult {
    id:              string;
    date:            string;
    answerCount:     number;
    unAnswerCount:   number;
    areas:           Area[];
    correctCount:    number;
    passed:          boolean;
    isCertification: boolean;
    isAssessment:    boolean;
    assesmentLevel:  number;
    score:           number;
    testId:          null;
    title:           null;
    identity:        null;
    email:           null;
    remainTestTime:  number;
    isTimeEnded:     boolean;
    errorMessage:    null;
    isError:         boolean;
    firstName:       null;
    lastName:        null;
}

export interface Area {
    id: string;
    name: string;
    description: string;
}