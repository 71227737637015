import { Card, Group, Badge, Divider, Stack, Button, Text, Drawer, Title, Loader, ScrollArea, HoverCard, TextInput, Textarea, Spoiler } from "@mantine/core";
import { useLanguage } from "../../../common/language/Language";
import { GlobalSecurity } from "../../../common/security/GlobalSecurity";
import { Monitoring, Onboarding, ProctorApi, ProctorSession } from "../api/ProctorApi";
import { useDisclosure, useMediaQuery, useOs } from "@mantine/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher, Http } from "../../../common/Fetcher";
import { ResultBase } from "../../../common/api/common";
import { DateTime } from "luxon";
import { renderMarkdown } from "../../../common/content/RenderMarkdown";



export const MonitoringWidget = (props: {session: ProctorSession}) => {
    const lang = useLanguage();
    const {accessToken} = GlobalSecurity();
    const [showEventsPanel, { open: openEventsPanel, close: closeEventsPanel }] = useDisclosure(false);
    const [showChatPanel, { open: openChatPanel, close: closeChatPanel }] = useDisclosure(false);
    const [showVideoPanel, { open: openVideoPanel, close: closeVideoPanel }] = useDisclosure(false);
    const [showAiPanel, { open: openAiPanel, close: closeAiPanel }] = useDisclosure(false);

    const os = useOs();
    const isMobile = os === "ios" || os === "android";
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const [summaryLoading, setSummaryLoading] = useState(false);
    

    const {data } = useSWR<ResultBase<Monitoring>>(
        accessToken!==undefined && props.session.monitoringId!==undefined ? 
        ["/api/proctor/getmonitoring?id=" + props.session.monitoringId, accessToken] : null,
        () => fetcher("/api/proctor/getmonitoring?id=" + props.session.monitoringId, Http.GET, accessToken), {
            keepPreviousData: false,
            refreshInterval: 1000,
            // revalidateOnFocus: true,
            onError: (err) => {
                console.error(err);    
            },
        },            
    );

    const [item, setItem] = useState<Monitoring | undefined>(undefined);

    useEffect(() => {
        console.info("Monitoringwidget", data);
        if (data !== undefined && data.success) {
            setItem(data.result);
            setError(false);
        }
        else
        {
            if (!data?.success)
            {
                setError(true);
                setErrorMessage(lang.Text(data?.errorMessage));
            }
        }
    }, [data]);


    const getEventColor = (eventType?: number) => {
        switch (eventType) {
            case 0: return 'gray.6';
            case 1: return 'gary.6';
            case 2: return 'gray.6';
            case 3: return 'gray.6';
            case 4: return 'blue';
            case 5: return 'blue.6';
            case 6: return 'green';
            case 7: return 'red';
            case 8: return 'cyan';
            case 9: return 'teal';
            default: return 'gray.6';
        }
    }
    
    const updateAiProctor = () => {
        console.info("get ai summary...", item?.id);
        setSummaryLoading(true);
        ProctorApi.getSummary(item?.id, accessToken).then((result) => 
        {
            setSummaryLoading(false);
        })
        .catch((err) => { console.error(err);  setSummaryLoading(false); });
    }

    return (<>
    {props.session.monitoringId !==null ?
    <Card className="min-h-[200px]" withBorder>
        <Group justify="space-between" >
            <Text className="font-bold">{lang.Text('Exam')}</Text>
            <Badge color={ProctorApi.Monitoring.getStatusColor(item)}>{ProctorApi.Monitoring.getStatusText(item, lang)}</Badge>
        </Group>
        <Divider variant="dashed" className="my-2" />
        {error ? <Text color="red">{errorMessage}</Text>: <>
        <Stack className="min-h-[130px] text-xs pt-2" gap={0}>
            {item?.events.slice(item?.events.length-4,item?.events.length).reverse().map((event, key) => {
                return (<Group key={key} justify="start" gap={0}>
                    <Badge color={getEventColor(event.eventTypeCode)} size="xs" className="min-w-[140px]">
                        {DateTime.fromISO(event.eventTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
                    </Badge>
                    <Text className="text-sm mx-2" truncate="end" >{event.eventText}</Text>
                    {/* <Text>{event.eventType}</Text> */}
                </Group>)
            })
            }
        </Stack>
        <Group justify="end">
            <Button variant="transparent" color="primary" size="sm" onClick={openAiPanel} 
                    rightSection={<FontAwesomeIcon  icon={duotone('microchip-ai')} size="2xl" className="text-brand"/>}>
            </Button>
            {item?.isFinished ? <></> : <Loader type="dots"/>}
            <Button variant="subtle" color="primary" size="sm" onClick={openVideoPanel}>
                <FontAwesomeIcon  icon={light('video')} size="lg"/>
            </Button>
            <Button variant="subtle" color="primary" size="sm" onClick={openChatPanel}>
                <FontAwesomeIcon  icon={light('message-dots')} size="lg"/>
            </Button>
            <Button variant="subtle" color="primary" size="sm" onClick={openEventsPanel}>
                <FontAwesomeIcon  icon={light('list-dots')} size="lg"/>
            </Button>
        </Group>
        </>}
    </Card>: <></>}

    {/* events panel */}
    <Drawer opened={showEventsPanel} position="right" title={<Title order={3} className="mt-2">{lang.Text('Monitoring')}</Title>} onClose={closeEventsPanel} withCloseButton size={isMobile || isSmallScreen ? '100%': '850px' }>
        <Divider variant="dashed" className="my-4" />
        <Card className="" withBorder  radius="md">
            <Group justify="space-between" >
                <Text className="font-bold">{props.session.examTitle}</Text>
                <Badge color={ProctorApi.getStatusColorValue(props.session.status)}>{props.session.status}</Badge>
                {item?.isFlagged ? <FontAwesomeIcon  icon={duotone('flag')} size="lg" className="text-amber-500"/> : <></>}
            </Group>
            
            <Stack className="text-xs mt-4" >
                <Group >
                    <Text>{lang.Text('Started:')}</Text>
                    {item?.createdDate && <Text className="font-bold">{DateTime.fromISO(item?.createdDate).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</Text>}
                </Group>
                <Group >
                    <Text>{lang.Text('Ended:')}</Text>
                    {item?.endDate && <Text className="font-bold">{DateTime.fromISO(item?.endDate).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</Text>}
                </Group>
                {item?.errorMessage && <Group >
                    <Text>{lang.Text('Error:')}</Text>
                    <Text className="font-bold text-red-400">{item?.errorMessage}</Text>
                </Group>}
                {item?.isFlagged && <Group grow align="top">
                    <Text >{lang.Text('Flagged Reason:')}</Text>
                    <Textarea value={item?.flaggedReason} onChange={(e) => {}}/>
                </Group>}
                {item?.isFlagged && <Group grow align="top">
                    <Text>{lang.Text('Flagged Internal Reason:')}</Text>
                    <Textarea value={item?.flaggedInternalReason} onChange={(e) => {}}/>
                </Group>}
            </Stack>
        </Card>
        {/* AiProcotor */}
        {item?.summary &&
        <Card withBorder className="mt-4"  radius="md">
        <Stack>
            <Group justify="space-between">
                <Group>
                    <FontAwesomeIcon  icon={duotone('microchip-ai')} size="2xl" className="text-brand"/>
                    <Text>Summary</Text>
                </Group>
                {summaryLoading ? <Loader type="dots"/>: 
                <Button variant="subtle" color="gray" onClick={updateAiProctor} 
                >
                    <FontAwesomeIcon  icon={regular("rotate-right")} />
                </Button>}
            </Group>
            {item?.summary &&
            <Spoiler maxHeight={70} showLabel="Show more" hideLabel="Hide" className="">
                {/* {renderMarkdown(item?.summary)} */}
                <Text>{item?.summary}</Text>
            </Spoiler>}
        </Stack>
        </Card>}
        {/* Action bar */}
        <Group className="mt-4" justify="start">
            <HoverCard shadow="md" openDelay={700}>
                <HoverCard.Target>
                    <Button variant="subtle" color="primary" size="sm" onClick={openChatPanel} >
                        <FontAwesomeIcon  icon={light('message-dots')} size="lg"/>
                    </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <Text size="sm">{lang.Text('Open chat panel')}</Text>
                </HoverCard.Dropdown>
            </HoverCard>
            <HoverCard shadow="md" openDelay={700}>
                <HoverCard.Target>
                    <Button variant="subtle" color="primary" size="sm" >
                        {item?.isFlagged ? <FontAwesomeIcon  icon={duotone('flag')} size="lg" className="text-amber-500"/> :
                            <FontAwesomeIcon  icon={light('flag')} size="lg"/>
                        }
                    </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <Text size="sm">{lang.Text('Flag the exam for suspicious activity to be investigated later.')}</Text>
                </HoverCard.Dropdown>
            </HoverCard>
            <HoverCard shadow="md" openDelay={700}>
                <HoverCard.Target>
                    <Button variant="subtle" color="red" size="sm" >
                        <FontAwesomeIcon  icon={light('ban')} size="lg"/>
                    </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <Text size="sm">{lang.Text('End the exam, flag for suspicious activity')}</Text>
                </HoverCard.Dropdown>
            </HoverCard>
        </Group>
        <Divider variant="dashed" className="my-4" />
        <Stack className="min-h-[130px] text-xs pt-2">
            <ScrollArea className="h-[50vh]">
            {item?.events.slice(item?.events.length-3,item?.events.length).reverse().map((event, key) => {
                return (<Group key={key} justify="start" gap={0}>
                    <Badge color={getEventColor(event.eventTypeCode)} size="xs" className="min-w-[150px]">
                        {DateTime.fromISO(event.eventTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
                    </Badge>
                    <Text className="text-sm mx-2" truncate="end" >{event.eventText}</Text>
                    {/* <Text>{event.eventType}</Text> */}
                </Group>)
            })
            }
            </ScrollArea >
        </Stack>
    </Drawer>

    {/* chat panel */}
    <Drawer opened={showChatPanel} position="right"
        title={<Title order={3} className="mt-2">{lang.Text('Proctor - chat')}</Title>}
        onClose={closeChatPanel} withCloseButton size={isMobile || isSmallScreen ? '100%': '650px' }>
       
        <Divider variant="dashed" className="my-4" />
    </Drawer>

    {/* video panel */}
    <Drawer opened={showVideoPanel} position="right"
        title={<Title order={3} className="mt-2">{lang.Text('Proctor - Video')}</Title>}
        onClose={closeVideoPanel} withCloseButton size={isMobile || isSmallScreen ? '100%': '750px' }>
        <Divider variant="dashed" className="my-4" />
    </Drawer>

    {/* video panel */}
    <Drawer opened={showAiPanel} position="right"
        title={<Title order={3} className="mt-2">{lang.Text('Ai')}</Title>}
        onClose={closeAiPanel} withCloseButton size={isMobile || isSmallScreen ? '100%': '750px' }>
        <Divider variant="dashed" className="my-4" />
    </Drawer>
    </>);
}
