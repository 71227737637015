import { duotone, light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Group,Title, Text, Loader,  Card, Image, RingProgress, SimpleGrid, Container, Divider, Stack, Avatar, Badge, Menu, Progress, ActionIcon, TextInput, useMantineTheme, Button, NavLink, LoadingOverlay, DefaultMantineColor, Alert, Timeline, Center } from "@mantine/core";
import { useForceUpdate, useMediaQuery, useOs } from "@mantine/hooks";


import { useLanguage } from "../common/language/Language";

import { usePubsub, useSubscribe } from "../common/pubsub/usePubsub";

import { AuthenticatedContent } from "../common/security/components/AuthenticatedContent";

import { GlobalSecurity } from "../common/security/GlobalSecurity";

import { Footer } from "../common/content/Footer";

import { Module } from "../common/api/Module";
import { useState } from "react";

import { ProctorModule } from "../modules/proctoring/ProctoringModule";
import { AlertModule } from "../common/components/dashboard/Alert";
import { PaymentModule } from "../modules/payment/PaymentModule";
import { MetricsModule } from "../modules/metrics/MetricsModule";

import { useNavigate } from "react-router-dom";
import { DashboardGrid, ModuleTopBar } from "../common/components/dashboard/DashboardGrid";
import { OrganisationModule } from "../modules/organisationManagement/OrganisationModule";
import { CalendarModule } from "../modules/TestcenterManagement/CalendarModule";
import { VoucherModule } from "../modules/TestcenterManagement/VoucherModule";
import { MonitoringModule } from "../modules/TestcenterManagement/MonitoringModule";



export const InfoModule = () => {

    return (<div className="p-4">  
         <ModuleTopBar
            title={"System Info"}
            icon={"YC"}
            iconColor={"gray"}
            badge={<Badge color='green' variant='outline'>Updated 2023-04-15</Badge>}
        >
            <Menu.Label>Application</Menu.Label>
            <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("gears")}/>}>Settings</Menu.Item>
            <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("message-check")}/>}>Messages</Menu.Item>
            <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("gallery-thumbnails")}/>}>Gallery</Menu.Item>
            

        </ModuleTopBar> 

              <Text fz="lg" fw={500} mt="md">
                5.3 minor release (April 2023)
              </Text>
              <Text fz="sm" c="dimmed" mt={5}>
                Form context management, Switch, Grid and Indicator components improvements, new hook and
                10+ other changes
              </Text>

              <Text c="dimmed" fz="sm" mt="md">
                Upgrade completed:{' '}
                <Text
                  span
                  fw={500}
                >
                  23/36
                </Text>
              </Text>

              <Progress value={(23 / 36) * 100} mt={5} />
    
    </div>)

}




export const PlaceHolderModule = (props: {module?: Module}) => {
    const lang = useLanguage();
    return (<div className="p-4">
         <ModuleTopBar
                title={props.module?.identifier}
                icon={"YC"}
                badge={<></>}
            >
            <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("gears")}/>}>Settings</Menu.Item>
    </ModuleTopBar>
    {/* <Text className="mt-2">{props.module?.id}</Text> */}

    </div>)
}

export const Dashboard = () => {
    const lang = useLanguage();
    const theme = useMantineTheme();
    const {isLoggedIn, isLoading, organisation, userProfile} = GlobalSecurity();
    const [loading, setLoading] = useState(false);
    
    const os = useOs();
    const isMobile = os === "ios" || os === "android";
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1220px)');
    
   
    useSubscribe('global.organisationChanged', (msg: string, data:any) => {
        //console.log('global.organisationChanged', data);
        //setLoading(true);
        //forceUpdate();
    });


    const renderModules = () => {
        let active_modules: any[] = [];
        organisation?.modules.forEach((module: Module) => {
            switch(module.identifier) {
                case 'organisation.management':
                    active_modules.push(<OrganisationModule />);
                    break;
                case 'testcenter.metrics':
                    active_modules.push(<MetricsModule />);
                    break;
                case 'testcenter.vouchers':
                    active_modules.push(<VoucherModule />);
                    break;
                case 'testcenter.info':
                    active_modules.push(<InfoModule />);
                    break;
                case 'testcenter.calendar':
                    active_modules.push(<CalendarModule />);
                    break;
              
                case 'remote.proctoring':
                    active_modules.push(<ProctorModule />);
                    break;
                case 'portal.payment':
                    active_modules.push(<PaymentModule />);
                    break;
                case 'testcenter.monitoring':
                        active_modules.push(<MonitoringModule />);
                        break;
                default:
                    active_modules.push(<PlaceHolderModule module={module}/>);
                    break;
            }
        });
        return ( <DashboardGrid modules={active_modules}/>)
    }

    return (<>
        <LoadingOverlay visible={loading} overlayProps={{blur: 2}} />
        <AuthenticatedContent path="/dashboard">
        <div className="mt-[40px] p-4 sm:p-6  min-h-[80vh]"  >
            <AlertModule id="dashboard.alert"/>
            {renderModules()}
        </div>
        </AuthenticatedContent>
        <Footer/>
    </>
    );

}