import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SimpleGrid, Skeleton, Container, Stack, useMantineTheme, px, Title, Center, Paper, Image, Text, Card, Badge, Group, Progress, Avatar, Menu, Button } from '@mantine/core';
import { useMediaQuery, useOs } from '@mantine/hooks';

export interface DashBoardGridProps {
    height?: number
    title?: string
    modules?: any[]
}

export const ModuleWrap = (props: {children: any, height?: number}) => {

  return (<Card 
    withBorder 
      h={props.height} radius="md" shadow='md'
      padding={0}
    >
    {props.children}
    </Card>
  )
}

export const ModuleTopBar = (props: {
    children?:any,
    menu?: any, title?: any, badge?: any,
    icon?:any, iconColor?: any
  }) => {
  return (<>
    <Group justify='space-between'>
        <Avatar color={props.iconColor? props.iconColor : "blue"} variant='filled' radius="xl">{props.icon}</Avatar>
        <Text fw={700}>{props.title}</Text>
        {props.badge}
        {props.menu?
        <Menu trigger="hover" openDelay={100} closeDelay={400} withinPortal shadow="lg">
            <Menu.Target>
            <div className='cursor-pointer'>
              <FontAwesomeIcon  icon={regular("ellipsis-vertical")} className="block h-6 w-6"  aria-hidden="true" />
            </div>
            </Menu.Target>
            <Menu.Dropdown >
               {props.menu}
            </Menu.Dropdown>
        </Menu>: <></>}
        {props.children?
        <Menu trigger="hover" openDelay={100} closeDelay={400} withinPortal shadow="lg">
            <Menu.Target>
            <div className='cursor-pointer'>
              <FontAwesomeIcon  icon={regular("ellipsis-vertical")} className="block h-6 w-6"  aria-hidden="true" />
            </div>
            </Menu.Target>
            <Menu.Dropdown >
               {props.children}
            </Menu.Dropdown>
        </Menu>: <></>}
      </Group>
  </>)
}

export const DashboardGrid = (props: DashBoardGridProps) => {    
    const BASE_HEIGHT = props.height ? props.height : 900;
    const getSubHeight = (children: number, spacing: number) =>
      BASE_HEIGHT / children - spacing * ((children - 1) / children);
    
    const renderModule = (height: number, index: number) => {
      if (props.modules!==undefined && props.modules[index]!==undefined)
      return (<><ModuleWrap height={height}>{props.modules[index]}</ModuleWrap></>);
    }


    return (<>
    <Stack className='mt-4'>
      {/*render the first 7 modules */}
      <SimpleGrid cols={{base: 1, xl: 3, md: 2, xs: 1,}} >
          {renderModule(BASE_HEIGHT, 0)}
          <Stack>
            {renderModule(getSubHeight(2, 15),1)}
            {renderModule(getSubHeight(2, 15),2)}
          </Stack>
          <Stack>
            {renderModule(getSubHeight(3, 15),3)}
            {renderModule(getSubHeight(3, 15),4)}
            {renderModule(getSubHeight(3, 15),5)}
          </Stack>
          {renderModule(BASE_HEIGHT,6)}

        {/* If more than 7 modules, render the rest */}
        {props.modules!==undefined && props.modules.length > 7? 
          <>
          {renderModule(BASE_HEIGHT, 7)}
        <Stack>
          {renderModule(getSubHeight(2, 2),8)}
          {renderModule(getSubHeight(2, 2),9)}
        </Stack>
        <Stack>
          {renderModule(getSubHeight(3, 2),10)}
          {renderModule(getSubHeight(3, 2),11)}
          {renderModule(getSubHeight(3, 2),12)}
        </Stack>
          {renderModule(BASE_HEIGHT,13)}
          </> : <></>}
      </SimpleGrid>
    
      </Stack>
    </>
    )
}