import { regular, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Stack, RingProgress, Divider, Group, Button, Text, Avatar } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { ModuleTopBar } from "../../common/components/dashboard/DashboardGrid";
import { useLanguage } from "../../common/language/Language";

export const VoucherModule = () => {
    const lang = useLanguage();
    const navigate = useNavigate();
    
    return (<div className="p-4">
    <ModuleTopBar
                title={lang.Text("Vouchers")}
                icon={"YC"}
                badge={<></>}
            >
           
                {/* <Button variant="light" color="blue" >{lang.Text('Buy more')}</Button> */}
                <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("shopping-cart")}/>}
                    rightSection={<FontAwesomeIcon  icon={duotone("arrow-up-right-from-square")}/>}
                >{lang.Text('Buy more')}</Menu.Item>

    </ModuleTopBar>
    <Stack className='mt-4'  align="stretch" justify="space-between" >    
        <Group grow className=" p-2" justify="start" align="start">
            <Stack >
                <Group>
                    <Text size="xs">{lang.Text('Vouchers in store')}:</Text>
                        <RingProgress
                        sections={[{ value: 45, color: 'blue' }]}
                        label={
                        <Text fw={700}  size="xl" className="text-center">
                        400
                        </Text>
                        }/>
                </Group>        
            </Stack>
            <Stack justify="space-between" >
                <Group justify="space-between">
                    <Text className="font-bold">{lang.Text('Used today')}:</Text>
                    <Avatar color="blue"  size="md">{3}</Avatar>
                </Group>
                <Group justify="space-between">
                    <Text >{lang.Text('Used this month')}:</Text>
                    <Avatar color="blue"  size="md">{24}</Avatar>
                </Group>
                {/* <Group justify="space-between">
                    <Text >{lang.Text('Sessions today')}:</Text>
                    <Avatar color="blue"  size="md">{3}</Avatar>
                </Group> */}
                {/* <Group justify="space-between">
                    <Text c={"red"}>{lang.Text('Errors')}:</Text>
                    <Avatar color="red"  size="md">{1}</Avatar>
                </Group> */}
                {/* <Group justify="space-between">
                    <Text c={"red"} >{lang.Text('Result waiting')}:</Text>
                    <Avatar color="red"  size="md">{1}</Avatar>
                </Group> */}
            </Stack>
        </Group>
        <div>
            <Divider variant="dashed" className="w-full my-1 py-1" />
            <Group justify="end">
                <Button variant="subtle" className="text-base" rightSection={<FontAwesomeIcon  icon={duotone("arrow-right")}/>}
                            onClick={() => navigate('/vouchers')}
                            >{lang.Text('Buy more Vouchers')}
                </Button>
            </Group>
        </div>
    </Stack>
    </div>)
}
