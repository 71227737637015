import { Accordion, Group, Badge, SimpleGrid, CopyButton, Button, Text, Image } from "@mantine/core";
import { useState, useEffect } from "react";
import useSWR from "swr";
import { fetcher, Http } from "../../../common/Fetcher";
import { ResultBase } from "../../../common/api/common";
import { useLanguage } from "../../../common/language/Language";
import { GlobalSecurity } from "../../../common/security/GlobalSecurity";
import { ProctorSession, ProctorApi } from "../api/ProctorApi";
import { MonitoringWidget } from "./MonitoringWidget";
import { OnboardingWidget } from "./OnboardingWidget";
import { ResultWidget } from "./ResultWidget";
import { useMediaQuery } from "@mantine/hooks";

export const ProctorSessionItem = (props: {session: ProctorSession}) => {
    const lang = useLanguage();
    const {accessToken} = GlobalSecurity();

    const isLargeScreen = useMediaQuery('(min-width: 1720px)');

    const [item, setItem] = useState<ProctorSession>(props.session);

    const {data } = useSWR<ResultBase<ProctorSession>>(
        accessToken!==undefined ? ["/api/proctor/getsession?id=" + item.id + "&orgid=" + item.orgId, accessToken] : null,
        () => fetcher("/api/proctor/getsession?id=" + item.id + "&orgid=" + item.orgId, Http.GET, accessToken), {
            keepPreviousData: true,
            refreshInterval: 5000,
            revalidateOnFocus: true,
            onError: (err) => {
                console.error(err);    
            },
        },            
    );

    useEffect(() => {
        if (data !== undefined && data.success) {
            ProctorApi.fromSession(data.result);
            console.info("refresh ->", data.result);
            setItem(data.result);
        }
    }, [data]);
    
    return (<>
        <Accordion.Item value={item.id} >
            <Accordion.Control >
                <Group justify="space-between">
                    <Group >
                        <Image src={props.session.image} maw={64} />
                        <Text className="font-bold">{item.description}</Text>
                        {item.type && <Badge color="indigo.7" className="mx-4">{item.type}</Badge>}
                    </Group>
                    <Badge className="mx-4" color={ProctorApi.getStatusColorValue(item.status)}>{item.status}</Badge>
                </Group>
            </Accordion.Control>
            <Accordion.Panel>
                <SimpleGrid cols={{base: 4, xl: isLargeScreen ? 4: 3, lg: 3, md: 2, sm: 2, xs: 1}}>
                   <OnboardingWidget id={item.onboardingId}/>
                   <MonitoringWidget session={item}/>
                   <ResultWidget session={item}/>
                </SimpleGrid>
                <Group justify="space-between" className="mt-1">
                    <Text size="xs" color="dimmed">{item.orgId}</Text>
                    <Text size="xs" color="dimmed">{item.testcenter}</Text>
                    <CopyButton value={item.id}>
                        {({ copied, copy }) => (<Group >
                            <Text size="xs" color="dimmed">{item.id}</Text>
                            <Button size="xs" variant="subtle" color={copied ? 'teal' : 'blue'} onClick={copy}>
                                 {copied ? 'Copied!' : 'Copy id'}
                            </Button></Group>
                        )}
                    </CopyButton>
                </Group>
            </Accordion.Panel>
        </Accordion.Item>
    </>);
}