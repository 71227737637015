import * as React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../../common/language/Language'
import { GlobalSecurity } from '../../../common/security/GlobalSecurity';
import { ScheduleSettings, ScheduleSettingsOpeningHours, Testcenter } from '../api/Testcenter';
import useSWR from 'swr';
import { ResponseData, ResultData } from '../../../common/api/common';
import { Http, fetcher } from '../../../common/Fetcher';
import { Badge, Button, Card, Divider, Group, Paper, SegmentedControl, Tabs, Text, Title, Image, RingProgress, Drawer, ActionIcon, Menu, Input, Loader } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AlertModule } from '../../../common/components/dashboard/Alert';
import  moment  from 'moment';
import { ScheduleSessionsComp } from '../components/ScheduleSessions';
import { Calendar, DatePicker } from '@mantine/dates';
import { CalendarWeekView } from '../components/CalendarWeekView';
import { CalendarMonthView } from '../components/CalendarMonthView';


export interface ManageScheduleProps {

}



export const ManageSchedulePage = () => {

    const lang = useLanguage();
    const navigate = useNavigate();

    const { accessToken, userProfile, organisation, currentOrganisationId } = GlobalSecurity();

    const [ calendarValue, setCalendarValue] = useState(null);

    const [calendarDate, setCalendarDate] = useState<Date>(new Date());

    const [ shouldLoadTestcenter, setShouldLoadTestcenter] = useState<boolean>(true);
    const [ loading, setLoading ] = useState<boolean>(true);
    // const [ testcenter, setTestcenter] = useState<Testcenter>();
    const [ scheduleSettings, setScheduleSettings] = useState<ScheduleSettings>();

    const [viewValue, setViewValue] = useState<string>('weekview');

   // setViewValue('schedule');

    const { data, mutate } = useSWR<ResponseData<ScheduleSettings>>(
        accessToken!==undefined && shouldLoadTestcenter && organisation? [ '/api/schedule/getScheduleSettings?testcenterId=' + organisation.id + '&testcenterParentId=' + organisation.parent, accessToken] : null,
        () => fetcher( '/api/schedule/getScheduleSettings?testcenterId=' + organisation?.id + '&testcenterParentId=' + organisation?.parent, Http.GET, accessToken), {
            keepPreviousData: false,
            refreshInterval: 0,
            onError: (err) => {
                
            }
        }  

        // shouldLoadTestcenter? [
        //     '/api/testcenter/gettestcenter?id=' + organisation?.id + '&parent=' + organisation?.parent,
        //     'GET',
        //     accessToken
        // ] : null, fetcher, {
        //  onSuccess:(data) => {
        //     setLoading(false);
        //     setShouldLoadTestcenter(false);
     
        //  }   
        // }
    );

    useEffect(() => {
        if(data?.success && data.data != undefined && data.data != null) {
           //console.log(data);
           // setTestcenter(data.data);
           setScheduleSettings(data.data);
         
            setShouldLoadTestcenter(false);
        }
    }, [data])

    // useEffect(() => {
    //     if(fetchTestcenterData?.success && fetchTestcenterData.isValid){
    //         setTestcenter(fetchTestcenterData.data);
    //     }
    // }, [fetchTestcenterData])
    
    const openingHoursItem = (ohItem : ScheduleSettingsOpeningHours) => {
        console.log(ohItem);
        return <Group justify="space-between">
                    <Text>{lang.Text(ohItem.startDayLangKey)}</Text> {ohItem.endDayLangKey != null? <Text>{" - " + lang.Text(ohItem.endDayLangKey)}</Text> : <></>}
                    <Text >10:00 - 18:00</Text>
                </Group>
    }

    const openingHours = () => {

        // var openingHoursEntries = [];

        // if(scheduleSettings != null && scheduleSettings != undefined) {
        //     if(scheduleSettings.openingHours != null) {
        //         scheduleSettings.openingHours.forEach(oh => {
        //             openingHoursEntries.push(<Group justify="space-between">
        //                 <Text>{lang.Text(oh.startDayLangKey)}</Text> {oh.endDayLangKey != null? <Text>- {lang.Text(oh.endDayLangKey)}</Text> : <></>}
        //                 <Text align='right'>10:00 - 18:00</Text>
        //             </Group>)
        //         })
        //     }
        // }

        // return <>{openingHoursEntries}</>;

        // return (<><Group justify="space-between">
            
        //         <Text>Monday - Sunday</Text><Text align='right'>10:00 - 18:00</Text>
                
        //         </Group><Group justify="space-between">
            
        //     <Text>Monday - Sunday</Text><Text align='right'>10:00 - 18:00</Text>
            
        //     </Group></>);
    }

    const onDateChange = (date: Date) => {

        console.log(date);
    }

    return(<>
    <Paper className="pt-20 p-10 lg:mx-16 md:mx-2 min-h-screen" shadow="md" radius="sm" withBorder>
    <Group justify="start" className="ml-[-30px]">
            <Button variant="subtle"  className="text-sm" leftSection={<FontAwesomeIcon icon={duotone('backward')}/>}
                onClick={() => navigate('/dashboard')}>
                {lang.Text('Back to dashboard')}
            </Button>
        </Group>
        <Group className="mt-4 w-full"  justify="space-between">
            <Title order={2}>{lang.Text('Calendar')}</Title>
            <AlertModule id="payments.alert"/>
            <Badge>{scheduleSettings?.testcenter.name}</Badge>
        </Group>
        <Divider variant="dashed" className="my-4" />
        <Group className="mt-4" justify="space-between" >
            <Group>
             <SegmentedControl
                color="violet"
                size="md"
                value={viewValue}
                onChange={setViewValue}
                data={[
                    { label: lang.Text('Overview'), value: 'monthview' },
                    { label: lang.Text('Week'), value: 'weekview' },
                    // { label: lang.Text('Monitoring'), value: 'monitoring' },
                    { label: lang.Text('Schedule'), value: 'schedule' },
                    { label: lang.Text('Settings'), value: 'settings' },
                ]}
                />
            
                <Button 
                   c={"gray"}
                    leftSection={<FontAwesomeIcon  icon={duotone("plus")} size="xl"/>}
                    variant="subtle" 
                    // onClick={}
                    >{lang.Text('Add Session')}
                </Button>
            </Group>
            <Group >
                <Input placeholder="Search bookings" className='w-full'
                    rightSectionPointerEvents="all"
                    mt="md"
                    rightSection={
                      <FontAwesomeIcon icon={duotone('search')} />
                    }
                />
            </Group>
            <Group grow align='end'>
                <Menu trigger="click" 
                     openDelay={100} closeDelay={400} withinPortal shadow="lg">
                   <Menu.Target>
                    <ActionIcon className='float-right' variant="subtle" size={"lg"}>
                        <FontAwesomeIcon  icon={regular("gear")} size="xl"
                                color="darkgray"  aria-hidden="true" />
                    </ActionIcon>
                   </Menu.Target>
                   <Menu.Dropdown className="p-4 min-w-[330px]">
                   {/* <Menu.Label>Application</Menu.Label> */}
                          <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("remove")}/>} 
                        //   onClick={clearSessions}
                          >{lang.Text('Clear sessions')}</Menu.Item>
                          <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("message-check")}/>}>{lang.Text('Write Report')}</Menu.Item>
                        <Menu.Divider className=""/>
                       
                   </Menu.Dropdown>
                </Menu>

            </Group>

         
        </Group>

        <Divider variant="dashed" className="my-4" />
        <div className="lg:flex lg:h-full lg:flex-col">
        <Tabs value={viewValue}>
        <Tabs.Panel value="monthview">
                <Loader />
                
        </Tabs.Panel>
        <Tabs.Panel value="weekview">
                { scheduleSettings?<>
               <CalendarWeekView />
               </>
                : null    
                } 
                
        </Tabs.Panel>
        <Tabs.Panel value="dayview">
                { scheduleSettings?
             <CalendarWeekView  />
                : null    
                } 
                
        </Tabs.Panel>
        <Tabs.Panel value='schedule'>
            <Title order={4}>{lang.Text('Schedule')}</Title>
            
                <Title order={5}>{lang.Text('Opening hours')}</Title>
                <Divider variant='solid' className='my-4' />
                 { scheduleSettings && scheduleSettings.openHours? 
                    <>
                        { scheduleSettings.openHours.map(oh => {
                            return <Group justify="space-between" key={oh.startDayLangKey}>
                                {
                                    oh.endDayLangKey != null? 
                                    <Group><Text>{ lang.Text(oh.startDayLangKey) + ' - ' + oh.endDayLangKey }</Text></Group> 
                                    : 
                                    <Group><Text>{lang.Text(oh.startDayLangKey)}</Text></Group>
                                }
                            <Text >{moment(oh.startTimeUtc).format('HH:mm') + " - " + moment(oh.endTimeUtc).format('HH:mm')}</Text>
                        </Group>
                        }) }
                    </> : null
                    }
                <Title order={5}>{lang.Text('Daily schedule')}</Title>

                <Divider variant='solid' className='my-4' />
                {scheduleSettings && scheduleSettings.scheduleDefinition && scheduleSettings.scheduleDefinition.calendarDays.length > 0? 

                    <ScheduleSessionsComp scheduleSettings={scheduleSettings} />
                    :
                    <></> 
                    }
        </Tabs.Panel>
        <Tabs.Panel value='settings'>
            <></>
        </Tabs.Panel>
        </Tabs>
        </div>  
    {scheduleSettings? <>
            
        </>: <></>}   
        
    </Paper>
       

    </>)
}