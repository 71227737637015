import { PropsWithChildren } from "react";
import { GlobalSecurity } from "../GlobalSecurity";
import { Alert, Loader, Title, Text, Group, Center, Button, Stack } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useLanguage } from "../../language/Language";
import { useContent } from "../../content/useContent";
import { renderMarkdown } from "../../content/RenderMarkdown";
import { usePubsub } from "../../pubsub/usePubsub";

export interface AuthenticatedContentProps {
  children: PropsWithChildren<any>;
  path?: string;
}

export const AuthenticatedContent = (props: AuthenticatedContentProps) => {
    const { isLoggedIn, login, isLoading, hasOrganisation, hasInvite } = GlobalSecurity();
    const lang = useLanguage();
    const about = useContent({contentID: 'Nc9LkLhmzFg8fQKZq1Cg1', locale: lang.locale(), preview: false});
    const app = usePubsub();
    
    if (isLoading) {
      return <div className="min-h-screen">
        <Center>
          <Loader variant="dots" size={'md'} />
        </Center>
      </div>;
    }

    if (isLoggedIn && hasOrganisation) {
      return <>{props.children}</>;
    }

    if (isLoggedIn && hasInvite) {
      //redirect to invite page
      return <></>;
    }
    
    return (<div className="min-h-[80vh]">
    <Alert 
     color={'blue.3'}
     //bg={'blue.1'}
      className="border-1 border-slate-300 p-8 m-28"
     radius="md"
    //  icon={<FontAwesomeIcon  icon={duotone("info-circle")} color={'blue'} size="2x" aria-hidden="true" />}>
     > 
      <Title className="mb-8" order={1}>{about.contentItem?.caption}</Title>

      <Group grow>
        {renderMarkdown(about?.contentItem?.copy)}
        <Center>
            <Stack>
            <Button size="lg" onClick={() => login(props.path)}>{lang.Text('Login')}</Button>
            <div className="my-4">
              {lang.Text('Or get an special invite here:')} <Button variant="outline" size="md"
                onClick={() =>
                  app.publish("intercom.show.newmessage",
                     "I like to get a VIP invite, please Contact me.")}>{lang.Text('Get invite')}</Button>
            </div>
            </Stack>
        </Center>
      </Group>
    </Alert>
    </div>);
};