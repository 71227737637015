import { useNavigate } from "react-router-dom";
import { Module } from "../../common/api/Module";
import { useLanguage } from "../../common/language/Language";
import { regular, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Stack, Loader, Divider, Button, Text, Paper, Title, Group, Badge, useMantineTheme, SegmentedControl, Autocomplete, Image, 
    Avatar, Accordion,  Drawer, ActionIcon, TextInput,  Container,Center, Card, SimpleGrid, Timeline, Chip,} from "@mantine/core";

import { useDisclosure, useLocalStorage} from "@mantine/hooks";
import { forwardRef, useEffect, useState } from "react";
import { GlobalSecurity } from "../../common/security/GlobalSecurity";
import { ProctorApi, ProctorData, ProctorModuleInfo, ProctorSession,} from "./api/ProctorApi";
import { useContent } from "../../common/content/useContent";
import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { usePubsub } from "../../common/pubsub/usePubsub";
import { AlertModule } from "../../common/components/dashboard/Alert";
import { AuthenticatedContent } from "../../common/security/components/AuthenticatedContent";
import { Footer } from "../../common/content/Footer";
import { fetcher, Http } from "../../common/Fetcher";


import { ProctorSessionItem } from "./components/ProctorSessionItem";

import { ModuleTopBar } from "../../common/components/dashboard/DashboardGrid";
import useSWR from "swr";

export const ProctorModule = (props: {module?: Module}) => {
    const lang = useLanguage();
    let navigate = useNavigate();
    const {accessToken} = GlobalSecurity();
    const [isLoading, setIsLoading] = useState(true);

    const {data,  mutate} = useSWR<ProctorData>(
        accessToken!==undefined ? ["/api/proctor/GetProctorData", accessToken] : null,
        () => fetcher("/api/proctor/GetProctorData", Http.GET, accessToken), {
            keepPreviousData: true,
            refreshInterval: 0,
            revalidateOnFocus: true,
            onError: (err) => {
                console.error(err);    
            },
        },            
    );

    const [proctorInfo, setProctorInfo] = useState<ProctorModuleInfo>();

    useEffect(() => {
        if (data?.success){
            console.log("ProctorData:",data);
            setProctorInfo(data.result);
        }
        else
        {
            data?.errorMessage && console.error(data.errorMessage);
        }
        setIsLoading(false);
    }, [data]);

    const refresh = () => {
        setIsLoading(true);        
        mutate(data).then(() => {
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        });
    }

    return (<div className="p-4">
         <ModuleTopBar
                title={lang.Text("Remote Proctoring")}
                icon={"YC"}
                badge={<></>}
            >
            <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("rotate-right")}/>}
                onClick={() => refresh()}>{lang.Text('Refresh')}
            </Menu.Item> 
            <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={duotone("arrow-right")}/>}
                onClick={() => navigate('/proctoring')}
                >{lang.Text('Open proctoring')}
            </Menu.Item> 
        </ModuleTopBar>
        {isLoading ? <Center><Loader type="dots" /></Center> :
        <Stack className='mt-4'  align="stretch" justify="space-between">
            {proctorInfo?.numOfSessions !==undefined && proctorInfo?.numOfSessions > 0 &&
            <Group justify="space-between">
                <Text>{lang.Text('Sessions In progress')}:</Text>
                <Avatar color="green"  size="md">{proctorInfo?.numOfSessions}</Avatar>
            </Group>}
            {proctorInfo?.numOfOnboarding !==undefined && proctorInfo?.numOfOnboarding > 0 &&
            <Group justify="space-between">
                <Text>{lang.Text('Onboarding')}:</Text>
                <Avatar defaultChecked color="violet"  size="md">{proctorInfo?.numOfOnboarding}</Avatar>
            </Group>}
            {proctorInfo?.numOfError !==undefined && proctorInfo?.numOfError > 0 &&
            <Group justify="space-between">
                <Text>{lang.Text('Errors')}:</Text>
                <Avatar defaultChecked color="red"  size="md">{proctorInfo?.numOfError}</Avatar>
            </Group>}
            {proctorInfo?.numOfFlagged !==undefined && proctorInfo?.numOfFlagged > 0 &&
            <Group justify="space-between">
                <Text>{lang.Text('Flagged')}:</Text>
                <Avatar defaultChecked color="yellow"  size="md">{proctorInfo?.numOfFlagged}</Avatar>
            </Group>}
            {/* <Center><Loader variant="dots" /></Center> */}
            <Divider variant="dashed" className="w-full my-1" />
            <Group justify="end">
                <Button variant="subtle" className="text-base" rightSection={<FontAwesomeIcon  icon={duotone("arrow-right")}/>}
                    onClick={() => navigate('/proctoring')}
                    >{lang.Text('Open proctoring')}</Button>
            </Group>
        </Stack>}
        
    </div>)
}



interface ItemProps  {
    id: string;
    code: string;
    description: string;
    image: string;
    testcenter: string;
    status: string;
    value: string;
  }

/**
 * Renders an item in an autocomplete dropdown list.
 *
 * @component
 * @example
 * const item = {
 *   id: "1",
 *   email: "example@example.com",
 *   description: "Example Item",
 *   image: "example.jpg",
 *   testcenter: "Test Center",
 *   status: "in progress"
 * };
 *
 * <AutoCompleteItem {...item} />
 */
const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ id, code, description, value, image, testcenter, status,
    
    ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} >
      <Group >
        <Avatar src={image} />
        <div>
          <Text size="sm">{description}</Text>
          {/* <Text size="xs" color="dimmed">
             {value}
          </Text> */}
          <Text size="xs" color="dimmed">
            {testcenter}
          </Text>
        </div>         
          <Badge color={ProctorApi.getStatusColorValue(status)}>{status}</Badge>
      </Group>
    </div>
  )
);

export const ProctoringPage = (props: {module?: Module}) => {
    const lang = useLanguage();
    const theme = useMantineTheme();
    const {organisation, accessToken} = GlobalSecurity();
    const app = usePubsub();
    const navigate = useNavigate();

    const [filterValue, setFilterValue] = useState('onboarding');
    const [addSessionPanel, { open: openAddPanel, close: closeAddPanel }] = useDisclosure(false);
    const [addLoading, setAddLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchSessionValue, setSearchSessionValue] = useState('');
    const [searchMessage, setSearchMessage] = useState('');
    const [searchResults, setSearchResults] = useState<ProctorSession[]>([]);
    
    //testdata
    const [data, setData] = useLocalStorage<ProctorSession[]>(
        { key: 'yc-proctor-sessions', defaultValue: [
        // { id: '9c69fb81-2d7f-4cd9-b88c-ae5009e9a01f', value: '9c69fb81-2d7f-4cd9-b88c-ae5009e9a01f', group: 'Onboarding', status: 'onboarding', email: 'test3@test.com', description: 'AGRC KYC', image: '', testcenter: '', type: 'remote' },
        // { id: 'b2753cb5-ee59-4579-aaac-5b0290de5b52', value: 'b2753cb5-ee59-4579-aaac-5b0290de5b52', group: 'In progress', status: 'inprogress', email: 'test@test.com', description: 'AGRC KYC', image: '', testcenter: '', type: 'remote' },
        // { id: 'f70b34c9-c82e-4ab3-8660-802046da248a', value: 'f70b34c9-c82e-4ab3-8660-802046da248a', group: 'In progress', status: 'inprogress', email: 'bosse@test.com', description: 'AGRC RISK', image: '', testcenter: 'Testcenter 1', type: undefined },
        // { id: 'e3ddb1cc-a335-42d1-a0ce-d6168ed3a602', value: 'e3ddb1cc-a335-42d1-a0ce-d6168ed3a602', group: 'Error', status: 'error', email: 'nisse@test.com', description: 'EADPP Basic', image: '', testcenter: 'Testcenter 2', type: undefined },
        // { id: 'e09cb2c9-69ab-4a1b-abfe-139a35cb7e7a', value: 'e09cb2c9-69ab-4a1b-abfe-139a35cb7e7a', group: 'Finished', status: 'finished', email: 'olga@test.com', description: 'LPI Test1', image: '', testcenter: 'Testcenter 4', type: undefined },
      ]});

    const about = useContent({contentID: '1MhdZvyiED6gGvynL5Qrb1', locale: lang.locale(), preview: false});
    

    const renderItems = () => {
        var filterData = data;
        if (filterData !== undefined && data !== undefined)
        {
            if (filterValue==='all') {
                //do nothing
            }
            else {
                filterData = data.filter((item: ProctorSession) => item.status.toLowerCase() === filterValue.toLowerCase());
            }

            if (searchValue.length > 0) {
                filterData = filterData.filter((item: ProctorSession) => item.id.toLowerCase().includes(searchValue.toLowerCase().trim()));
            }
       
            return filterData.map((item: ProctorSession) => (
                <ProctorSessionItem session={item} key={item.id}/>
            ));
        }
    }

    const selectSession = (item: any) => {
        console.info("selectSession", item);
        //setFilterValue('all');
    }

    const checkifAdded = (id: string) => {
        if (data !== undefined)
        {
            let found = data.find((item: ProctorSession) => item.id === id);
            return found !== undefined;
        }
    }

    const searchSessions  =() => {
        setAddLoading(true);
        setSearchMessage('');
        setSearchResults([]);

        fetcher('/api/proctor/findsessions?searchText=' + searchSessionValue, Http.GET , accessToken).then((response) => {
            console.info("searchSessions", response);
            if (response.success)
            {
                if (response.result !== undefined)
                {
                    searchResults.push(response.result);
                    setSearchResults(response.result);
                    setSearchMessage('');
                }
                else
                {
                    setSearchMessage(lang.Text('No sessions found'));
                }
            }
            else
            {
                setSearchMessage(lang.Text(response.errorMessage));
            }
        }).catch((error) => {
            console.error("searchSessions error", error);
            setSearchMessage(lang.Text('No sessions found'));
        }).finally(() => {
            setAddLoading(false);
        });
    }
    
    const addSession = (session : ProctorSession) => {
        ProctorApi.fromSession(session);
        let sessions: ProctorSession[] | undefined = data;
        if (sessions!==undefined)
        {
            sessions.push(session);
            setData(sessions);
            closeAddPanel();
        }
    }

    const clearSessions = () => {
        setData([]);
    }

    return (<div className="">
    <AuthenticatedContent path="/proctoring">
     <Paper className="pt-20 p-10 lg:mx-16 md:mx-2 min-h-screen" shadow="md" radius="sm" withBorder>
        <Group justify="start" className="ml-[-30px]">
            <Button variant="subtle"  className="text-sm" leftSection={<FontAwesomeIcon icon={duotone('backward')}/>}
                onClick={() => navigate('/dashboard')}>
                {lang.Text('Back to dashboard')}
            </Button>
        </Group>
        <Group className="mt-4 w-full"  justify="space-between">
            <Title order={2}>{lang.Text('Proctoring')}</Title>
            <AlertModule id="proctor.alert"/>
            <Badge>{organisation?.name}</Badge>
        </Group>
        <Divider variant="dashed" className="my-4" />
        {/* Action bar */}
        <Group className="mt-4" justify="space-between" >
            <Button 
                leftSection={<FontAwesomeIcon  icon={duotone("plus")} size="xl"/>}
                variant="filled" onClick={openAddPanel}>{lang.Text('Add session')}
            </Button>
            {/* <Text>{lang.Text('Filter sessions:')}</Text> */}
            <SegmentedControl
                color={ProctorApi.getStatusColorValue(filterValue)}
                size="md"
                value={filterValue}
                onChange={setFilterValue}
                data={[
                    { label: lang.Text('All'), value: 'all' },
                    { label: lang.Text('Onboarding'), value: 'onboarding' },
                    { label: lang.Text('In progress'), value: 'in progress' },
                    { label: lang.Text('Flagged'), value: 'flagged' },
                    { label: lang.Text('Error'), value: 'error' },
                    { label: lang.Text('Finished'), value: 'finished' },
                ]}
                />
            {/* <Autocomplete
                value={searchValue}
                onChange={setSearchValue}
                // itemComponent={AutoCompleteItem}
                placeholder={lang.Text("Start typing to search")}
                //data={data.map((item: ProctorSession) => {ProctorApi.getSessionitem(item); return item;})}
                // onItemSubmit={(item: ProctorSession) => selectSession(item)}
                className="min-w-[450px]"
                // filter={(value: string, item: any) =>
                //     item.id.toLowerCase().includes(value.toLowerCase().trim()) ||
                //     //item.testcenter.toLowerCase().includes(value.toLowerCase().trim()) ||
                //     item.title.toLowerCase().includes(value.toLowerCase().trim()) ||
                //     item.code.toLowerCase().includes(value.toLowerCase().trim())
                //   }
                rightSection={<ActionIcon size={32} variant="subtle"
                        onClick={() => setSearchValue('')}>
                        <FontAwesomeIcon  icon={regular("delete-left")} color="darkgray" size="xl"/>
                </ActionIcon>}                
                /> */}
                {/* <ActionIcon size="xl" onClick={() => refresh.start()}>
                    <FontAwesomeIcon  icon={regular("refresh")} color="darkgray" size="xl"/>
                </ActionIcon> */}
                {/**More functions  */}
                <Menu trigger="click" 
                     openDelay={100} closeDelay={400} withinPortal shadow="lg">
                   <Menu.Target>
                    <ActionIcon className='float-right' variant="subtle" size={"lg"}>
                        <FontAwesomeIcon  icon={regular("gear")} size="xl"
                                color="darkgray"  aria-hidden="true" />
                    </ActionIcon>
                   </Menu.Target>
                   <Menu.Dropdown className="p-4 min-w-[330px]">
                   {/* <Menu.Label>Application</Menu.Label> */}
                          <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("remove")}/>} 
                          onClick={clearSessions}>{lang.Text('Clear sessions')}</Menu.Item>
                          <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("message-check")}/>}>{lang.Text('Write Report')}</Menu.Item>
                        <Menu.Divider className=""/>
                       <Button className="m-4" onClick={() => app.publish("proctor.alert",{
                                title: "Test",
                                text: "This is a test",
                                color: "red"
                        }) }>Test</Button> 
                   </Menu.Dropdown>
                </Menu>
        </Group>
        <Divider variant="dashed" className="my-4" />

        {/* Session items */}
        <Accordion 
            variant="contained"
            radius="md" 
            multiple
            className="min-h-[50vh]"
            >
            {renderItems()}
        </Accordion>   

        {/* help and history */}
        <Accordion variant="contained" radius="md" className="mt-8">
        <Accordion.Item value="flexibility">
            <Accordion.Control >
                <Title order={4}>{lang.Text('About Proctoring')}</Title>
                </Accordion.Control>
            <Accordion.Panel>
                {renderMarkdown(about.contentItem?.copy)}
            </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="focus-ring">
            <Accordion.Control>
            <Title order={4}> {lang.Text('History')}</Title>
               </Accordion.Control>
            <Accordion.Panel>
                <Text>{lang.Text('No history yet')}</Text>
            </Accordion.Panel>
        </Accordion.Item>
        </Accordion>   
        

        {/* Add session panel */}
        <Drawer opened={addSessionPanel} position="right"
             title={<Title order={3} className="mt-2">{lang.Text('Add session')}</Title>}
            onClose={closeAddPanel} withCloseButton size="35%">
            
            
            <TextInput
                data-autofocus
                leftSection={<FontAwesomeIcon  icon={duotone("search")} size="xl"/>}
                radius="xl"
                size="md"
                rightSection={
                    <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled"
                        onClick={() => searchSessions()}>
                        <FontAwesomeIcon  icon={regular("arrow-right")} size="xl"/>
                    </ActionIcon>
                }
                placeholder={lang.Text("SessionId, BookingId, Code, testcenterId")}
                rightSectionWidth={42}
                {...props}
                onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                        searchSessions();
                    }
                }}
                onChange={(event) => {setSearchSessionValue(event.target.value)}}
                value={searchSessionValue}
                />
            <Divider variant="dashed" className="my-4" />
            <Container className="mt-8 min-h-[30vh]">
               <Center>
               {addLoading ? <Loader type="dots" size={'lg'}/>
               : <>
               <Text>{searchMessage}</Text>
               </>} 
               </Center>
               {searchResults && <>
               {searchResults.map((item, key) => {
                    return <>
                    <Group className="mt-4" justify="space-between" key={key}>
                        <Text className="font-bold">{item.examTitle}</Text>
                        <Badge color={ProctorApi.getStatusColorValue(item.state)}>{item.state}</Badge>
                        {/* <Text>{item.testcenter}</Text>
                        <Text>{item.id}</Text> */}
                        <div>
                        {checkifAdded(item.id) ? <>
                            <FontAwesomeIcon  icon={duotone("check")} className="text-green-600" size="xl"/>
                        </>:
                            <Button variant="outline" color="primary"
                                onClick={() => addSession(item)}>
                                {lang.Text('Add')}
                            </Button>}
                        </div>
                    </Group>
                    <Divider variant="dashed" className="my-2" />
                    </>
               })}
               </>}

            </Container>
        </Drawer>
            
    </Paper>
    </AuthenticatedContent>
    
    <Footer/>
    </div>)
}

