import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { duotone, light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Group, Image, Loader, SimpleGrid, Stack, Stepper, Title, Text, TextInput, PasswordInput, Accordion, Grid, Alert, Center, Card, LoadingOverlay, Paper, Chip, Progress, Badge, CopyButton } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { ResponseData } from "../../common/api/common";
import { useContent } from "../../common/content/useContent";
import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { fetcher, Http } from "../../common/Fetcher";
import { GlobalAppsettings } from "../../GlobalSettings";
import { useLanguage } from "../../common/language/Language";
import { MyBookingInfo, ProductItem } from "./api/Onboarding";
import { usePubsub } from "../../common/pubsub/usePubsub";
import luxon, { DateTime } from "luxon";
import { ContentItem } from "../../common/content/api/Content";

export interface IOnboardingProps {
    id: string | null;
    part?: string | null;
}

export const MyBooking = (props : IOnboardingProps) => {
    const lang = useLanguage();
    const app = usePubsub();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isBookingFound, setIsBookingFound] = useState(false);

    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const [active, setActive] = useState(0);
    const [setupUser, setSetupUser] = useState(false);

    const form = useForm({
        initialValues: {
            username: '',
            password: '',
        },
            ///(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/
        validate: (values) => {
            if (active === 0) {
              return {
                username:
                  values.username.trim().length < 6
                    ? 'Username must include at least 6 characters'
                    : null,
                password: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/.test(values.password) ?  null
                : 'Password must include at least 8 characters, one uppercase, one lowercase, one number and one special character',
              };
            }
            return {};
        },
      });

      const nextStep = () =>
      setActive((current) => {
        if (form.validate().hasErrors) {
          return current;
        }

        if (current === 0) {
            console.log("Setup user...");
            setSetupUser(true);
        }

        return current < 3 ? current + 1 : current;
      });
  
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const hasprops = props.id != null && props.id !== '' && props.part != null && props.part !== '';

    const [current, setCurrent] = useState<MyBookingInfo>();

    const { data } = useSWR<ResponseData<MyBookingInfo>>(hasprops 
                ? '/api/portal/getonboardingforbooking?id=' + props.id
                + '&partId=' + props.part : null, fetcher, {
            refreshInterval: 0, 
            keepPreviousData: true,
            onSuccess: (data) => {
                setLoading(false);
            },
            onError: (error) => {
                console.error(error);
                setLoading(false);
            },
        });
    
    useEffect(() => {
        if (setupUser) {
            var info = {
                bookingId: props.id,
                onboardingid: props.part,
                email: form.values.username,
                password: form.values.password,
            }

            fetch(GlobalAppsettings.BaseUrl + "/api/portal/setupuser", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(info),
              })
                .then((response) => response.json())
                .then((data: ResponseData<string>) => {
                  console.info("setup response:");
                  console.info(data);
                
                  if (data.success) {
                    setActive(2);
                    setSuccess(true);
                    setSuccessMessage(lang.Text('You have successfully completed the onboarding!'));
                  }
                  else
                  {
                    setSuccess(false);
                    setSuccessMessage(undefined);
                  }
            });
        }
    }, [setupUser]);

    const {getContentItem} = useContent();
    const [exams, setExamsPolicy] = useState<ContentItem>();
    const [cancel, setCancelPolicy] = useState<ContentItem>();
        
    useEffect(() => {
        if (data?.success) {
            form.values.username = data.data.booking.email;
            //app.publish("intercom.setup-user", {email: data.data.booking.email})
            
            setCurrent(data.data);

            if (data.data.booking.productRef.policyContentId!==undefined) {
                getContentItem(data.data.booking.productRef.policyContentId,lang.locale()).then((item) => {
                    setExamsPolicy(item);
                }).catch((error) => {
                    
                });
            }
            else
            {
                getContentItem("7Ejcd5Zd6zejfh6WhbxF7g",lang.locale(),false).then((item) => {
                    setExamsPolicy(item);
                    
                }).catch((error) => {
                    
                });    
            }

            if (data.data.booking.productRef.cancelRefundPolicyContentId!==undefined) {
                getContentItem(data.data.booking.productRef.cancelRefundPolicyContentId,lang.locale()).then((item) => {
                    setCancelPolicy(item);
                }).catch((error) => {
                    
                });
            }
            else
            {   
                getContentItem("1N0yyw2A0tE4SRk7O9085W",lang.locale(),false).then((item) => {
                    setCancelPolicy(item);
                }).catch((error) => {
                    
                });
            }

            setError(false);
            setIsBookingFound(true);
        }
        else
        {
            console.error(data);
            setIsBookingFound(false);
            setError(true);
            setErrorMessage(data?.errorMessage);
        }
    }, [data]);


    //onboarding
    const step1 = useContent({contentID: '6OmBEyL4qA9LekdiQBuwLT', locale: lang.locale(), preview: false});
    const step2 = useContent({contentID: '6RyRri1xIFYusUXkdVDx6u', locale: lang.locale(), preview: false});

    //sections
    const onboarding = useContent({contentID: '5bFBnX2awr4jFUWPV3gUza', locale: lang.locale(), preview: false});
    const training = useContent({contentID: '6wEDYii3W7QHQk86UvdzuS', locale: lang.locale(), preview: false});
    const reciepts = useContent({contentID: '22mH4CPrBDAixAbV19RKxc', locale: lang.locale(), preview: false});
    const privacy = useContent({contentID: 'A4UablpAv9OJvJ1iglDyG', locale: lang.locale(), preview: false});

    // const exams = useContent({contentID: '7Ejcd5Zd6zejfh6WhbxF7g', locale: lang.locale(), preview: false});
    // const cancel = useContent({contentID: '1N0yyw2A0tE4SRk7O9085W', locale: lang.locale(), preview: false});
    
    const sectionHeader = (title?: string, icon?: IconProp) => {
        return (<Accordion.Control>
            <span><FontAwesomeIcon  icon={icon ? icon : duotone('page')} size="2x" color="#0476d9"/>
            <span className="px-4 text-xl font-bold">{title}</span>
            </span>
            </Accordion.Control>)
    }

    return  (<div className="min-h-[400px] mb-16">
    {loading ?<Center className="p-12"> <Loader variant="dots" /></Center> : 
    <div>
    {error ? <Alert icon={<FontAwesomeIcon icon={duotone('shield-exclamation')} size="2x"/>} 
            title={<Title order={4}>{lang.Text('Something needs your attention')}</Title>} color="red" radius="md">
            <Text>{errorMessage}</Text>
            <Group justify="end">
                <Button className="my-6" variant="outline" onClick={() => app.publish("intercom.show.newmessage",
                    "I need help with this error message: " + errorMessage)}>{lang.Text('Get Support')}</Button>
                </Group>
    </Alert> : <></>}

    {success ? <Alert icon={<FontAwesomeIcon icon={duotone('shield-check')} size="2x"/>} 
            title={<Title order={4}>{lang.Text('Success!')}</Title>} color="green" radius="md">
            <Text>{successMessage}</Text>
    </Alert> : <></>}

    {isBookingFound ? <>
    
    <Group justify="space-between" className="my-4">
        <Title order={2} className="py-6">{lang.Text('My Booking - ')}{current?.booking?.name}</Title>
        <Button leftSection={<FontAwesomeIcon icon={duotone('comments-question-check')} size="2x"/>}
            className="my-6" variant="subtle" onClick={() => app.publish("intercom.show.newmessage",
            "I need help with my booking: " + current?.purchase.id)}>{lang.Text('Get Support')}</Button>
    </Group>
    
   
    <Accordion variant="separated" radius="md" chevronPosition="right" 
        defaultValue={!current?.booking.onboardingIsCompleted ? "onboarding" : "exam"}
        >
    {!current?.booking.onboardingIsCompleted ?
    <Accordion.Item value="onboarding">
        {sectionHeader(onboarding.contentItem?.caption,duotone("rocket-launch"))}
        <Accordion.Panel className={"min-h-[450px]"}> 
    
        {current?.booking.hasTraining !==undefined ?
        <>
           <Stepper active={active}  className="px-2">
                <Stepper.Step label={step1.contentItem?.caption} description={step1.contentItem?.shortDescription} className="py-8">
                    {renderMarkdown(step1.contentItem?.copy)}
                <div className="p-6">
                <TextInput label="Username" placeholder="Username" {...form.getInputProps('username')} />
                    <PasswordInput
                        mt="md"
                        label="Password"
                        placeholder="Password"
                        {...form.getInputProps('password')}
                    />
                </div>
                </Stepper.Step>
                <Stepper.Step label={step2.contentItem?.caption} description={step2.contentItem?.shortDescription}>
                    {renderMarkdown(step2.contentItem?.copy)}
                </Stepper.Step>
                <Stepper.Completed>
                    
                </Stepper.Completed>
            </Stepper>
            
            <Group justify="center" mt="xl">
                <Button variant="default" onClick={prevStep}>{lang.Text('Back')}</Button>
                <Button onClick={nextStep}>{lang.Text('Next step')}</Button>
            </Group>
            </>: <></>}
        </Accordion.Panel>
    </Accordion.Item> : <></>}
    
    {current?.booking.hasTraining ?
    <Accordion.Item value="training">
        {sectionHeader(training.contentItem?.caption,duotone("graduation-cap"))}        
        <Accordion.Panel className="min-h-[400px]">
            <Stack className="p-6">
                <div>
                {renderMarkdown(training.contentItem?.copy)}
                </div>
                <Group className="">
                    {/* {courses?.map((item, key) => ( */}
                    <>
                    <Card key={'key'} shadow="sm" p="lg" radius="md" withBorder className=''>
                        <Card.Section key={"section1"} component="div" >
                            <Image
                                src={current?.booking.productRef.imageUrl}
                                h={160}
                                alt=""
                               radius={"md"}
                               fit="contain"
                            />
                        </Card.Section>

                        <Card.Section key={"section2"} className='px-4 py-2 h-16'>
                            <Title order={3} fw={700}>{current?.booking.productRef.title}</Title>
                        </Card.Section>

                        <Card.Section key={"section3"} className='px-4 py-2 h-16'>
                            <Button color={"cyan"} component={"a"} onClick={()=> window.open('https://agrc.talentlms.com','_new')}>{lang.Text('Open course')}</Button>
                        </Card.Section>
                    </Card>
                    </>

                </Group>
                <div>
                {renderMarkdown(training.contentItem?.slug)}
                </div>
            </Stack>
        </Accordion.Panel>
    </Accordion.Item> : <></>}

    <Accordion.Item value="exam">
        {sectionHeader(exams?.caption ,duotone("award"))}   
        <Accordion.Panel className="min-h-[400px]">
        <Stack className="p-6">
                <div>
                {renderMarkdown(exams?.copy)}
                </div>
                <Group >
                    <Card key={'key'} shadow="sm" p="lg" radius="md" withBorder className=''>
                        <Card.Section key={"section1"} component="div" >
                        <Image
                                src={current?.booking.productRef.imageUrl}
                                h={300}
                                //w={300}
                                alt=""
                               radius={"md"}
                               fit="contain"
                            />
                        </Card.Section>

                        <Card.Section key={"section2"} className='px-4 my-4 h-16 max-w-sm'>
                            <Text  lineClamp={3} fw={700}>{current?.booking.productRef.title}</Text>
                        </Card.Section>

                        <Card.Section key={"section3"} className='px-4 py-1 h-16'>
                            <Group>
                                <Button color={"grape"} component={"a"} rightSection={<FontAwesomeIcon icon={duotone('external-link')} />}
                                     onClick={()=> window.open(current?.booking.examLink,'_new')} size="lg">{lang.Text('Start Exam')}
                                </Button>
                                <Text><span className="text-gray-400">{lang.Text('Code: ')}</span>
                                    <span className="font-bold">
                                    {current?.booking.code}
                                    </span>
                                </Text>
                                {current?.booking.code &&
                                <CopyButton value= {current?.booking.code}>
                                {({ copied, copy }) => (
                                    <Button color={copied ? 'teal' : 'blue'} onClick={copy} variant="subtle"
                                        leftSection={<FontAwesomeIcon icon={light('copy')} />}>
                                     
                                     {copied ? lang.Text('Code Copied!') : lang.Text('Copy code')}
                                    </Button>
                                )}
                                </CopyButton>}
                            </Group>
                        </Card.Section>
                    </Card>
                </Group>
                <div>
                {renderMarkdown(exams?.slug)}
                </div>
                {current?.booking.results!==undefined && current?.booking.results.length>0 ? <Title order={4}>{lang.Text('Exam result(s)')}</Title> :<></>}
                <SimpleGrid cols={3} className="">
                    {current?.booking.results.map((item, key) => 
                    <Paper shadow={"md"} className="min-h-32" key={key} p={18} withBorder>
                     <Stack justify={"space-around"}>
                        <div>
                            <Group justify="space-between">
                                <Text size={"xs"} className="text-gray-400"> {lang.Text('Exam date: ')}
                                    {DateTime.fromISO(item.date).toFormat('yyyy-MM-dd').toString()}</Text>
                            {item.passed ? <FontAwesomeIcon icon={duotone('check-circle')} size="2x"  color="green"/> 
                            : <FontAwesomeIcon icon={duotone('circle-xmark')} size="2x" color="red"/>}
                            </Group>
                        </div>
                        <div>
                            <Badge size="md" defaultChecked>{lang.Text('Proctored Online')}</Badge>
                        </div>
                        <div>
                            <Text size={"xs"}>{lang.Text('Total Questions:')} {item.unAnswerCount + item.answerCount} </Text>
                            <Text size={"sm"}>
                                <span>{lang.Text('Score: ')}{item.score} {item.passed ? lang.Text('(Passed)') : <></>}</span> 
                            </Text>
                        </div>
                        <div>
                        <Progress.Root
                            mt="md"
                            size="xl"
                            radius="xl"
    
                        >
                            <Progress.Section value={item.correctCount} color='green'>
                                <Progress.Label>{lang.Text('Correct')}</Progress.Label>
                            </Progress.Section>
                            <Progress.Section value={item.unAnswerCount + item.answerCount} color='violet'>
                                <Progress.Label>{lang.Text('Incorrect')}</Progress.Label>
                            </Progress.Section>
                        </Progress.Root>
                        </div>
                        <div>
                        {/* <Group justify="space-between" className="py-2">
                            {item.passed? <Button variant="subtle" >{lang.Text('Certificate')}</Button>:
                            <Button variant="subtle" disabled>{lang.Text('See details')}</Button>}
                        </Group> */}
                        </div>
                      </Stack>
                    </Paper>)}
                </SimpleGrid>
            </Stack>
        </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="cancel">
        {sectionHeader(cancel?.caption,duotone("pen-ruler"))}   
        <Accordion.Panel className="min-h-[400px]">
        <Stack className="p-6">
                <Group>
                    <Button color={"red"} disabled={!current?.canBeOnCancelled}>{lang.Text('Cancel booking')}</Button>
                    {current?.canBeOnCancelled ?  <Text>{lang.Text("You can only cancel your booking if you haven't initiated your online exam.")}</Text>
                    : <Text>{current?.CannotBeCancelledReason!==undefined ?
                         lang.Text(current?.CannotBeCancelledReason) : 
                         lang.Text("You can only cancel your booking if you haven't initiated your online exam.You can only cancel your booking 24 hours before the start of the course.")}</Text>}
                </Group>
                <div>
                {renderMarkdown(cancel?.copy)}
                </div>
                <Group>
                <Button color={"green"} onClick={() => app.publish("intercom.show.newmessage",
                    "I Request an Refund on booking " + current?.purchase.id)}>{lang.Text('Request Refund')}</Button>
                </Group>
                <div>
                {renderMarkdown(cancel?.slug)}
                </div>

                <Group justify="center">
                    <FontAwesomeIcon icon={duotone('tree-palm')} size="2x"/>
                    <Text size="xs" className="text-gray-400"> {lang.Text('Nothing to show right now')}</Text>
                </Group>
            </Stack>

        </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="reciepts">
        {sectionHeader(reciepts.contentItem?.caption,duotone("file-certificate"))}   
        <Accordion.Panel className="min-h-[400px]">
        <div>
            {renderMarkdown(reciepts.contentItem?.copy)}
        </div>
        <div className="p-4 ">
            <Group justify="center">
                <FontAwesomeIcon icon={duotone('tree-palm')} size="2x"/>
                <Text size="xs" className="text-gray-400"> {lang.Text('Nothing to show right now')}</Text>
            </Group>
        </div>

        </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value="privacy">
        {sectionHeader(privacy.contentItem?.caption,duotone("books"))}   
        <Accordion.Panel className="min-h-[400px]">
        <div>
            {renderMarkdown(privacy.contentItem?.copy)}
        </div>
        <div>
            <Group>
                <Button color={"green"} disabled>{lang.Text('Clear All Personal Data')}</Button>
                <Button color={"blue"} onClick={() => app.publish("intercom.show.newmessage",
                    "I Request All My Personal Data Stored in YouCap Systems")}>{lang.Text('Request All Personal Data')}</Button>
            </Group>
        </div>
        <div>
            {renderMarkdown(privacy.contentItem?.slug)}
        </div>               
        </Accordion.Panel>
    </Accordion.Item>
    </Accordion>
            
    
    </>: <></>}
    </div>}
    </div>)
}