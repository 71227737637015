import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Group, Badge, Divider, Avatar, Button, Image, Text, Tooltip, Alert } from "@mantine/core";
import { useLanguage } from "../../common/language/Language";
import { usePubsub } from "../../common/pubsub/usePubsub";
import { GlobalSecurity } from "../../common/security/GlobalSecurity";




export const OrganisationModule = () => {
    const lang = useLanguage();
    const {organisation} = GlobalSecurity();

    const app = usePubsub();

    return (<>
          {organisation?.imageUrl ?
          <Card.Section>
            <Image src={organisation?.imageUrl} 
              alt="header" height={120} mah={180} 
              fit="cover"/>
          </Card.Section>: <></>}
            
          <Card.Section className="px-4" mt="md">
            <Group justify="space-between">
              <Text fw={700} className="text-3xl">
                {organisation?.name}
              </Text>
              <Badge size="sm">{organisation?.language}</Badge>
            </Group>
            <Text fz="sm" mt="xs">
            {organisation?.description}
            </Text>
          </Card.Section>

          <Card.Section className="px-4" mt="md">
            <Group justify="space-between">
              <Text fz="lg" fw={700}>
                {lang.Text('Contact')}
              </Text>
            </Group>
            <Text fz="sm" mt="xs">
            {organisation?.contactEmail}
            </Text>
          </Card.Section>
          <Divider variant="dashed" className="my-3 mx-4" />
          <Card.Section className="px-4" >
            <Text fz="lg" fw={700}>
                {lang.Text('Users')}
              </Text>
            <Group justify="start" className="mt-2">
                <Avatar.Group spacing={"sm"}>
                    <Tooltip label="Salazar Troop" withArrow><Avatar src="avatar.png" alt="it's me" /></Tooltip>
                    <Tooltip label="Mr Best" withArrow><Avatar color="violet" radius="xl">SD</Avatar></Tooltip>
                    <Tooltip label="Pungen" withArrow><Avatar color="cyan" radius="xl">MP</Avatar></Tooltip>
                    <Avatar>+5</Avatar>
                </Avatar.Group>
            </Group>
            <Group justify="end" className="mt-4">
                <Button className="" variant="subtle" color="grey"
                    leftSection={<FontAwesomeIcon  icon={duotone("user-cog")} size="xl"/>}
                    >{lang.Text('Manage users')}</Button>
                <Button className="" variant="subtle" color="blue"
                    leftSection={<FontAwesomeIcon  icon={duotone("plus")} size="xl"/>}
                    >{lang.Text('Invite users')}</Button>
            </Group>
          </Card.Section>
        <Divider variant="dashed" className="my-3 mx-4" />
        <Card.Section className="px-4" >
          {organisation?.notifications?.map((notification, index) => {
              return (
                <Alert title={<div><FontAwesomeIcon icon={duotone('note-sticky')} size="2xl"
                  className="mr-4"/>{notification.title}</div>}
                  //  variant="outline"
                  color="yellow" className="mb-4" 
                    withCloseButton 
                    
                    >
                  {notification.message}
                </Alert>
              )
          })}

          
          <Group justify="end" className="mt-4">
               
                <Button className="" variant="subtle" color="grey"
                    leftSection={<FontAwesomeIcon  icon={duotone("plus")} size="xl"/>}
                    >{lang.Text('Add note')}</Button>
            </Group>
        </Card.Section>
    </>)
}