import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "@mantine/core";
import { Module } from "../../common/api/Module";
import { ModuleTopBar } from "../../common/components/dashboard/DashboardGrid";
import { useLanguage } from "../../common/language/Language";



export const MonitoringModule = (props: {module?: Module}) => {
    const lang = useLanguage();
    return (<div className="p-4">
         <ModuleTopBar
                title={lang.Text('Monitoring')}
                icon={"YC"}
                badge={<></>}
            >
            <Menu.Item className="text-base" leftSection={<FontAwesomeIcon  icon={regular("gears")}/>}>Settings</Menu.Item>
    </ModuleTopBar>
    

    </div>)
}