import * as React from 'react'
import { ScheduleSettings, CalendarDay, Room, Schedule_Item_Session, Schedule_Item_Session_RoomConfiguration, Device } from '../api/Testcenter'
import { Button, Card, Group, Title, Text, Divider, SimpleGrid, Drawer, Box, Select, Alert } from '@mantine/core'
import { useLanguage } from '../../../common/language/Language'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState, useEffect } from 'react';
import moment from 'moment'
import { useMediaQuery } from '@mantine/hooks'
import { useForm } from '@mantine/form';
import { number, string } from 'yup'
import { useContent } from '../../../common/content/useContent'
import { AddSessionToScheduleRequest } from '../api/TestcenterRequests'
import { Http, fetcher } from '../../../common/Fetcher'
import { ResponseData } from '../../../common/api/common'
import { GlobalAppsettings } from '../../../GlobalSettings'
import { GlobalSecurity } from '../../../common/security/GlobalSecurity'
import { AddSessionComp } from './AddSessionComp'

import { DateTime, CalendarSystem } from 'luxon'

export interface ScheduleSessionsProps {
    scheduleSettings: ScheduleSettings;
}

export const ScheduleSessionsComp = (props: ScheduleSessionsProps) => {
    const lang = useLanguage();

    const isMobile = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');
    const { accessToken, isLoggedIn } = GlobalSecurity();

    const [openAddSession, setOpenAddSession] = useState<boolean>(false);
    const [addSessionDayOfWeek, setAddSessionDayOfWeek] = useState<string>();
    
    const [calendarDays, setCalendarDays] = useState<CalendarDay[]>(props.scheduleSettings.scheduleDefinition.calendarDays);

    const openAddSessionDrawer = (dayOfWeek: string) => {

        

        //var jsonDate = props.scheduleSettings.testcenter.createdDateUTC.toISOString();

        console.log(props.scheduleSettings.testcenter);
        const readableDate = props.scheduleSettings.testcenter.createdDateUTC.toLocaleString();

        const readableBookableDate = props.scheduleSettings.testcenter.testsBookableTo.toLocaleString();

        var date = DateTime.fromISO(readableDate);

    

        var bookableDate = DateTime.fromISO(readableBookableDate);

        // var dateObj = DateTime.fromJSDate(props.scheduleSettings.testcenter.testsBookableToUTC)

        console.log(date);
        console.log(bookableDate);

        date.setZone("America/Los_Angeles");
        console.log("Start fo week date:")
        console.log(date.startOf('week'));
        
        
        console.log(DateTime.now());
        setAddSessionDayOfWeek(dayOfWeek);
        setOpenAddSession(true);
    }

    const SessionAddedCallback = (addedSession: Schedule_Item_Session) => {
        setOpenAddSession(false);

        let tempCalendarDays = calendarDays;

        let selectedCalendarDay: CalendarDay = tempCalendarDays.filter(s => s.dayofWeekLangKey == addSessionDayOfWeek)[0];

        selectedCalendarDay.sessions.push(addedSession);

        let indexOfSelectedCalednarDay: number = tempCalendarDays.indexOf(selectedCalendarDay);
        tempCalendarDays[indexOfSelectedCalednarDay] = selectedCalendarDay;

        setCalendarDays(tempCalendarDays);
    }

    return <>
       {calendarDays.map((cd, index) => {
        return <><Group justify="space-between" key={index}>
                    <Title order={6}>{lang.Text(cd.dayofWeekLangKey)}</Title>
                    <Button variant="subtle" color="gray" rightSection={<FontAwesomeIcon  icon={duotone("plus-large")}/>}
                    onClick={() => openAddSessionDrawer(cd.dayofWeekLangKey)}
                    >{lang.Text('Add session')}</Button>
                </Group>
                <SimpleGrid cols={4}>
                    { cd.sessions.length > 0? cd.sessions.map((session, key) => {
                        return  <Card radius={'lg'} shadow='none' key={key} padding='lg' 
                        onClick={() => console.log(props.scheduleSettings)} style={{cursor: ''}}>
                        <Group justify="center" >
                            <Text fz="sm" fw={700}  >
                            <Text>{moment(session.startTime).format('HH:mm') + " - " + moment(session.endTime).format("HH:mm")}</Text>
                            </Text>
                        </Group>
                        <Divider variant='solid' className='my-2'/>
                        <Group justify="center">
                            <Button variant="subtle" color="gray" 
                                onClick={() => console.log('heey')}
                                ><FontAwesomeIcon  icon={duotone("pencil")}/></Button>
                            <Button variant="subtle" color="gray" 
                                onClick={() => console.log('heey')}
                                ><FontAwesomeIcon  icon={duotone("trash")}/></Button>
                        </Group>
                    </Card>
                    }) : <Text>{lang.Text("No sessions for").trim() + " " + lang.Text(cd.dayofWeekLangKey + "s")}</Text>}
                </SimpleGrid>
                      
       </>})}


       <Drawer opened={openAddSession}
            position="right"
            size={isMobile? '100%' : isLargeScreen ? '600px' : '600px'}
            padding={isMobile ? 'sm' : 'sm'}
            transitionProps={{ transition: 'slide-left' }}
            onClose={() => {setOpenAddSession(false)}}
            withCloseButton= {true}
            zIndex={1200}
        >
            { addSessionDayOfWeek? 
                <AddSessionComp sessionAdded={SessionAddedCallback} addSessionDayOfWeek={addSessionDayOfWeek} scheduleSettings={props.scheduleSettings} />
                :
                null
            }
        </Drawer>
    </>
}